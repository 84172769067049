.address-item-wrapper {
  border: 1px solid #afafaf;
  padding: 20px;
  width: calc(45% - 18px);
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 10px;
  height: 195px;
  box-sizing: border-box;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;
  &.add-address-btn-wrapper {
    border: 1px dashed #afafaf;
    .add-address-btn-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
  }
  .address-item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .address-actions {
      position: absolute;
      top: 143px;
    }
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.selected {
    border: 3px solid #1c89e0;
  }
}
