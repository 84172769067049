.navbar-container {
  display: flex;
  width: 100% !important;
  max-height: 100%;
  justify-content: space-between;
  background-color: #f9f9f9 !important;
  padding-bottom: 2px !important;

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .width {
    width: 200px;
  }
  .logo-wrapper {
    // width: 200px;
    position: relative;
    // text-align: center;
    &:hover {
      cursor: pointer;
    }
    .logo {
      height: 100%;
      object-fit: contain;
      margin-left: 15px;
    }
    .logo-text {
      color: #0089dc;
      font-weight: 600;
      font-size: 25px;
    }
  }
  .logout {
    color: white;
  }
  .ant-menu-horizontal {
    border-bottom-color: transparent;
    color: #707070;
    .ant-menu-submenu {
      border-bottom-color: transparent;
      color: #707070 !important;
    }
    .ant-menu-item,
    .ant-menu-submenu,
    .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title {
      color: #707070 !important;
      &:hover {
        color: #707070 !important;
        border-bottom-color: transparent;
      }
    }
  }
}

.myaccount-language {
  color: #707070;
  &:hover {
    color: #1890ff;
  }
}
.myaccount-selection {
  color: #707070;
  &:hover {
    color: #1890ff;
  }
}
