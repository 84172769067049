
.group-navbar-container {
  width: 100%;
  margin: auto 0;
  justify-content: center;
  align-items: center;
  background: white;
  .nav-bar-container {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: auto 0;
      align-items: center;
      margin-bottom: 10%;
      .logo-wrapper {
        width: 200px;
        position: relative;
        margin-right: 30px;
        // text-align: center;
        &:hover {
          cursor: pointer;
        }
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50%;
          width: 1px;
          background: #efefefa6;
        }
        .logo {
          width: 200px;
          height: 70%;
          object-fit: contain;
        }
        .logo-text {
          color: white;
          font-weight: 600;
          font-size: 20px;
        }
        .search-box {
          width:180px;
        }
      }
      .logout {
        color: white;
      }
      .ant-menu-horizontal {
        border-bottom-color: transparent;
        color: #ffffff;
        .ant-menu-submenu {
          border-bottom-color: transparent;
        }
        .ant-menu-item, .ant-menu-submenu, .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title {
          color: #ffffff !important;
          &:hover {
            color: #ffffff !important;
            border-bottom-color: transparent;
          }
        }
      }
      .ant-input-wrapper ant-input-group {
        margin: 10px;
      }
  }
  }
  .nav-bar-searchButton{
    margin-right:0px;
    background-color: #fc5f00 !important;
  }
  .nav-bar-searchButton:hover,
  .nav-bar-searchButton:focus {
    color: #fff;
    background-color: #fc7d2f !important;
    border-color: #fc7d2f !important; 
  }