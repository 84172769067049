@charset "UTF-8";
.pay-now-page-container .row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%; }

.pay-now-page-container .row .left-col {
  display: table-cell;
  padding: 0px 30px 0px 24px; }
  .pay-now-page-container .row .left-col .section-title {
    font-weight: 700;
    font-size: 22px;
    display: flex;
    margin-bottom: 10px; }
  .pay-now-page-container .row .left-col .steps-bar {
    margin-top: 10px;
    margin-bottom: 25px; }
    .pay-now-page-container .row .left-col .steps-bar .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: #1890ff; }
    .pay-now-page-container .row .left-col .steps-bar .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
      color: #1890ff; }
    .pay-now-page-container .row .left-col .steps-bar .ant-steps-item-title:after {
      content: "";
      height: 1px;
      width: 150px;
      background: #e8e8e8;
      display: block;
      position: absolute;
      top: 16px;
      left: 100%; }
  .pay-now-page-container .row .left-col .logo-wrapper {
    width: 100%;
    position: relative;
    height: 64px; }
    .pay-now-page-container .row .left-col .logo-wrapper:hover {
      cursor: pointer; }
    .pay-now-page-container .row .left-col .logo-wrapper:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50%;
      width: 1px;
      background: #dad0d0a6; }
    .pay-now-page-container .row .left-col .logo-wrapper .logo {
      height: 70%;
      object-fit: contain; }
    .pay-now-page-container .row .left-col .logo-wrapper .logo-text {
      color: #1c89e0;
      font-weight: 600;
      font-size: 25px; }

.pay-now-page-container .row .right-col {
  background-color: #fafafa;
  border: 2px solid #f2f2f2;
  box-shadow: -2px -1px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 28px 29px;
  width: 45%; }
  .pay-now-page-container .row .right-col – .ant-divider-horizontal {
    display: block;
    height: 1px;
    width: 100%;
    min-width: 100%;
    margin: 22px 0 !important;
    clear: both; }

.pay-now-page-container .checkout-disclaimer {
  font-size: 0.8em;
  padding: 5px 0 10px 0; }
  .pay-now-page-container .checkout-disclaimer span {
    font-weight: 600;
    color: #0089dc; }
    .pay-now-page-container .checkout-disclaimer span:hover {
      cursor: pointer; }

.pay-now-page-container .transaction {
  text-align: right;
  font-size: 0.8em;
  color: #333333;
  padding-top: 5px;
  margin-bottom: -15px; }

.pay-now-page-container .change_button {
  box-shadow: none;
  border-color: transparent;
  color: #40a9ff; }
  .pay-now-page-container .change_button:hover {
    color: #33658f;
    background-color: #fff;
    border-color: transparent; }
  .pay-now-page-container .change_button:focus {
    color: #40a9ff;
    background-color: #fff;
    border-color: transparent; }

.pay-now-page-container .secure-inner-wrapper {
  font-size: 12px;
  color: #6d6d6d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 7.5px;
  margin-top: 5px; }
