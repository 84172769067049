.giftCardModal {
    .warnningMessage {
        color: #1C89E0;
        margin-bottom: 10px;
        font-size: 15px;
        display: flex;
        justify-content: flex-start;
        align-content: center;
    }
}
