.customer-info-container {
    padding-top: 1px;

    .title {
        height: 100%;
        font-size: large;
        font-weight: 600;
        font-size: 16px;
    }

    .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .action {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .list-information {
        padding: 0px 10px 10px 25px;
    }

}