.payment-info-container {
    padding-bottom: 0.1vw;

    .title {
        height: 100%;
        font-size: large;
        font-weight: 600;
        font-size: 16px;
    }

    .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .action {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .list-information {
        padding: 0px 10px 10px 25px;
    }

    .checkout-shippinginfo {
        font-weight: bold;
    }

    .section-form-row {
        display: flex;
        justify-content: flex-start;
        align-content: center;

        .section-form-col {
            display: flex;
            justify-content: flex-start;
            align-content: center;

        }

        .align-center {
            align-items: center;
        }

        .align-top {
            align-items: flex-start !important;
        }
    }

}