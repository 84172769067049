.content {
    display: flex;
    justify-content: center;
    padding-right: 10px;
}

.title {
    font-size: 25px;
    margin-right: 5px;
}

.button {
    background: #333;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    height: 60px;
    line-height: 65px;
    margin-top: -30px;
    pointer-events: all;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-content: center;
    &:hover {
        background: white;
        color: black;
    }
    .icon {
        margin: auto auto
    }
}

.prev {
    left: 20px;
}

.next {
    right: 20px;
}

