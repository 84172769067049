$generalFontColor: #615d5c;
.phone {

  .floating-input-container {
  position: relative;
  width: 104%;
  display: flex;
  flex-direction: column-reverse;
  padding: 0vw 2vw 1.9vw 2vw;
  margin:0vw 0vw 0vw 0vw;
  box-sizing: border-box;
  .input-border-wrapper {

    position: relative;
    .input-border {
      position: absolute;
      transition: all 0.3s;
      width: 100%;
      animation: input-border-default 0.2s;
      height: 1px;
      &.active {
        animation: input-border-float 0.2s;
        height: 2px;
      }
    }
  }
  .ant-col-12{
    padding-left:0px !important;
    padding-right:-10px !important;
    margin-right:-10px !important;
    width:100px;


    .select-style {
      width: 90px;
      padding-right:0px !important;
      .ant-select-selection{
        border:none !important;
      }
    }
  }
 
  .floating-input {
    padding: 0.0vw 0vw 0.0vw 0vw;
    margin:0.0vw 0vw 0.2vw 1vw;
    height:2rem;
    transition: all 0.3s;
    touch-action: manipulation;
    font-size: 1em;
    background-color: transparent;
    border: 0;
    // border-bottom: 1px solid black;
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    color: $generalFontColor;
    &:disabled {
      background: #e6e6e6;
      color: $generalFontColor;
      opacity: 0.8;
    }
    &:disabled.no-disable-highlight {
      color: $generalFontColor;
      -webkit-text-fill-color: $generalFontColor;
      opacity: 1;
      background: inherit;
    }
    &.unfocused {
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        opacity: 0;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        opacity: 0;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        opacity: 0;
      }
      &:-moz-placeholder { /* Firefox 18- */
        opacity: 0;
      }
    }
  }
  .phone-label .label {
    // transition: all 0.3s;
    // transition caused bug where label would overlap with words in input on ios12. Unsure why transition is even needed as animation continue to work as intenteded
    animation: floating-input-default 0.2s;
    padding: 0vw 1.5vw 0vw 0vw;
    cursor: auto;
    touch-action: manipulation;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0.0vw, 1.9rem) scale(1.0);
    -webkit-transform: translate(0.0vw, 1.9rem) scale(1.0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    text-align: left;
    &.floating {
      margin-top:0.0em;
      transform: translate(0.0vw, 0.0vw) scale(0.7);
      -webkit-transform: translate(0.0vw, 0.0vw) scale(0.7);
      animation: floating-input-float 0.2s;
    }
  }
  .errorMsg {
    margin-top:1vw;
  }
}

@keyframes floating-input-default {
  0% {
    transform: translate(0vw, 0vw) scale(0.7);
  }
  100% {
    transform: translate(0vw, 1.9rem) scale(1.0);
  }
}

@keyframes floating-input-float {
  0% {
    transform: translate(0vw, 1.9rem) scale(1.0);
  }
  100% {
    transform: translate(0vw, 0vw) scale(0.7);
  }
}

@keyframes input-border-default {
  0% {
    height: 2px;
  }
  100% {
    height: 1px
  }
}

@keyframes input-border-float {
  0% {
    height: 1px
  }
  100% {
    height: 2px;
  }
}

}

