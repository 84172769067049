.review-cell-container {
    width: 100%;

    .user-icon {
        line-height: 42px;
        height: 42px;
        width: 42px;
    }

    .user-text-container {
        position: relative;
        min-height: 1px;
        padding-right: 12px;
        float: left;
        display: block;
        clear: both;
        margin: 3px 0 4px;
        color: #727578;
        font-size: 12px;

        .reviewer-name {
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.21;
        }

        .user-stats {
            line-height: 20px;

            .reviewer-review-count {
                width: 256px;
                height: 40px;
                text-align: left;
                color: #75787b;
                font-weight: 200;
            }
        }
    }


    .ratings-date {
        width: 100%;
        position: relative;
        min-height: 1px;
        padding: 0 12px 0 0;
        float: left;
        margin: 8px 0;
    }

    .review-text {
        width: 100%;
        max-height: 5.6em;
        overflow: hidden;
        position: relative;
        min-height: 1px;
        padding: 0 12px 0 0;
        line-height: 1.43;
        font-size: 1rem;
        font-weight: 100
    }

    .ant-rate-star {
        margin-right: 3px;
    }
}