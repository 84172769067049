.select-items-container {
  margin: 20px 0 12px 0;

  .breakout-pricing-messages {
    background: #fff;
    list-style-type: none;
    padding: 8px 0 20px 0;
    border-bottom: 1px solid #d5d8db;

    .price-discount {
      width: 50%;
      display: block;
      justify-content: flex-start;

      .oldPrice {
        color: black;
        text-decoration: line-through;
        font-size: 19px;
        font-weight: 400;
        margin: 0 3px;
      }

      .newPrice {
        margin-left: 2px;
        font-size: 19px;
        font-weight: 400;
        color: #2bb8aa;
      }

      .discount-message-wrapper {
        margin: 2px 0 0;
        color: #2bb8aa;
        text-align: center;
        vertical-align: middle;
        padding: 1px 12px;
        background-color: #f2f2f4;
        font-size: 15px;
      }
    }

    .message {
      width: 50%;
    }
  }

  .select-item-container {
    border-bottom: 1px solid #d5d8db;
    font-size: 1.2rem;
    margin-bottom: 20px;
    padding: 10px 0;

    .missing-item-message {
      color: #fc0048;
      margin-bottom: 4px;
      font-size: 15px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
    }

    .missing-item {
      border: 1px solid #fc0048;
    }

    .select-item {
      display: flex;
      justify-content: space-between;
      align-content: center;
      width: 100%;

      .select-dropdown-nm {
        width: 75%;
      }

      .select-dropdown-pc {
        margin-left: 20px;
      }

      .select-dropdown-class {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .buy-button {
    margin: 8px 0;
  }

  .opt-name {
    margin: auto 0;
    font-weight: 600;
  }

  .ant-select-selection__placeholder {
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-btn-primary {
    color: #fff !important;
    background-color: #2bb8aa !important ;
    border-color: #2bb8aa !important ;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    background-color: #00c3b0 !important;
    border-color: #00c3b0 !important;
  }
}
