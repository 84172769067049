.topbar-container {
  display: flex;
  max-height: 100%;
  margin: auto 0;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  }
  .top-bar-submenu-title {
    color: #707070;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 35px;
  }
  .top-bar-submenu-title:hover {
    color: #2bb8aa;
  }
  .top-bar-submenuContainer{
    max-height: 100% !important;
    max-width: 100% !important;
    margin: auto 0;
    padding: 1px 7px !important;
  }
  .top-bar-submenuQR{
    height: 210px !important;
    max-width: 100% !important;
    margin: auto 0;
    padding: 5px!important;
  }
  .top-bar-subItems {
    color: #707070;
  }
  .top-bar-subItems:hover {
    color: #2bb8aa;
  }
  .top-bar-qrImg {
    width: initial;
    height: 200px;
  }

  //TOP BAR CUSTOM STYLING
  .top-bar-chooseCity {
    width: 130px;
    border-right: 1px solid  rgba(171, 171, 172, 0.15) !important;}
  .top-bar-LogIn {
    margin-left: 30px;
    width: 50px;}
  .top-bar-SignUp {
    margin-right: 10px;
    width: 65px;
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;}
  .top-bar-emptySpace {
    width: 200px;
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;}
  .top-bar-app {
    width: 80px;
    border-left: 1px solid  rgba(218, 218, 218, 0.15) !important;
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;
    .ant-menu-vertical{
      padding: 10px;
      width: 480px;
    }
  }
  .top-bar-User{
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;}
  .top-bar-history {
    width: 95px;
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;}
  .top-bar-service {
    width: 98px;
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;}
  .top-bar-business {
    width: 110px;
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;}
  .top-bar-favorite {
    padding-left: 15px;
    width: 100px;
    border-right: 1px solid  rgba(218, 218, 218, 0.15) !important;}