$priceWidth: 140px;
$sectionPadding: 5px 15px;
$boldFontWeight: 700;

.itemInfo {
    .title {
      font-weight:  bold;
      font-size: 11px;
      color: #666666
    }
    .border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.10)
    }
    .order-item-header {
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-right: 0px !important;
        margin-left: 0px !important;
        .item-name {
          padding-left: 0px !important;
        }
        .item-price {
          text-align: right;
          padding-right: 0px !important;
        }
        .item {
          padding-left: 3px !important;
          padding-right: 3px !important;
          margin-right: 0px !important;
          margin-left: 0px !important;
        }
        .single-item-price {
          text-align: right;
        }
        .cnt {
          text-align: right;
        }
      }
  
    .itemOpt {
        font-size: small;
        margin-left: 15px;
    }
}