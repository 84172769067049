.category-list-bar-container {
    margin-top: 20px;
    margin-bottom: 57px;

    .title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #343434;
        margin: 15px 0px;
    }

    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
            margin: 0 auto;
            cursor: pointer;

            .icon-parent {
                margin: 0 27px;
                border-radius: 100%;
                font-size: 30px;
                width: 75px;
                height: 75px;
                display: flex;
                overflow: hidden;
                position: relative;
                cursor: pointer;
            }

            .icon {
                padding: 15px;
                margin-left: .1em;
                margin-top: 3px;
                height: 90%;
                width: 70px;
                background-size: cover;
                background-repeat: no-repeat;
                -webkit-transition: all .5s;
                -moz-transition: all .5s;
                -o-transition: all .5s;
                transition: all .5s;
                overflow: hidden;
            }

            .icon:before {
                content: "";
                display: none;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
        
            }

            &:hover .icon,
            &:focus .icon {
                background-color: #DCDCDC;
                -ms-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -webkit-transform: scale(1.2);
                -o-transform: scale(1.2);
                transform: scale(1.2);
            }

            &:hover .icon:before,
            &:focus .icon:before {
                display: block;
            }

            .name {
                width: 130px;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}