.category-filter-container {
    margin-top: 25px;

    .filter-title {
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 0;
        padding-bottom: 5px;
        margin-top: 10px;
        color: #333;
        font-size: 23px;
        font-weight: 500
    }

    .slider-input {
        margin: 25px 0;
        font-size: 15px;

        .input {
            margin: 5px;
        }
    }

    .slider {
        margin: 21px 10px 10px 12px;
    }

    .checkbox-item {
        margin: 5px 0;
        color: #333;
        font-size: 15px;
    }

    .ant-slider-rail {
        height: 10px;
    }

    .ant-slider-track {
        height: 10px;
    }

    .ant-slider-handle {
        width: 25px;
        height: 25px;
    }
}