$priceWidth: 140px;
$sectionPadding: 5px 15px;
$boldFontWeight: 700;

.price-info {
  .totals-item {
    text-align: left;
    display: flex;
    justify-content: space-between;

    &.large {
      font-size: 17px;
      font-weight: $boldFontWeight;
    }

    .totals-name {
      flex: 1;

      &.light {
        color: rgba(0, 0, 0, 0.68);
      }
    }

    .totals-price {
      text-align: right;
      width: $priceWidth;

      .large {
        font-weight: $boldFontWeight;
      }

      .ant-input-number {
        background-color: transparent;
        margin-top: 4px;
        margin-right: -24px;
        padding: 0 25px 0 0;
        border: none;
        border-radius: 0px;
        height: 100%;
      }

      .ant-input-number-input {
        text-align: right;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-bottom: 1px solid #d9d9d9;
        border-radius: 0px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .tips-row {
    width: 90%;
    margin-top: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;

    .tips-button {
      width: 100%;
      font-size: 12px;
      height: 25px;
    }
  }
}