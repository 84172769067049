.order-detail-control-panel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;

    .count-down-timer-timer-text {
        font-weight: bolder;
        color: #ffa500;
    }

    .pay-now-descrption-text {
        font-size: 15px;
        padding-right: 8px;
    }
}

.control-panel-button {
    margin-right: 5px;

    &:last-child {
        margin-right: 0px;
    }

}