$priceWidth: 140px;
$sectionPadding: 5px 15px;
$boldFontWeight: 700;


.order-detail-page-header-container {
  justify-content: space-between;
  align-items: center;

  .header-text-title {
    font-weight: bolder;
    font-size: 22px;
  }
}


.order-detail-content-container {
  .tooltip {
    position: absolute;
    right: 74px;
    top: 180px;
  }

  .content-header {
    padding: $sectionPadding;

    .status-wrapper {
      padding: 5px 40px 20px 40px;
    }

    .special-status {
      text-align: right;
      font-size: 25px;

      &.cancel {
        color: red;
      }
    }
  }

  .content-body {
    display: flex;

    .section-wrapper {
      padding: $sectionPadding;

      &.left-section-wrapper {
        flex: 1;
      }

      &.right-section-wrapper {
        min-width: 300px;
        flex: 0.45;
      }

      .store-name {
        display: inline-block;
      }
    }

    .order-items-wrapper {
      .order-item-header {
        display: flex;

        .item-name {}

        .item-price {
          text-align: right;
        }
      }

      .totals-wrapper {
        .totals-item {
          text-align: right;
          display: flex;

          &.large {
            font-size: 17px;
            font-weight: $boldFontWeight;
          }

          .totals-name {
            flex: 1;

            &.light {
              color: rgba(0, 0, 0, 0.45);
            }
          }

          .totals-price {
            width: $priceWidth;

            .large {
              font-weight: $boldFontWeight;
            }
          }
        }
      }
    }

    .messages-wrapper {
      max-height: 40vh;
      overflow: auto;

      .message-wrapper {
        display: flex;
        margin: 6px 0;
        flex-direction: column;

        &.store {
          align-items: flex-start;

          .message {
            border-radius: 0 8px 8px 8px;
            background: #f1f1f1;
          }
        }

        &.customer {
          align-items: flex-end;

          .message {
            border-radius: 8px 0px 8px 8px;
            color: white;
            background: rgb(30, 137, 224);
            background: linear-gradient(90deg, rgba(30, 137, 224, 1) 0%, rgba(80, 166, 236, 1) 100%);
          }
        }

        .message {
          max-width: 75%;
          padding: 8px 10px;
          border: 1px solid #eee;
          font-weight: 500;
        }

        .date {
          font-size: 10px;
        }
      }
    }
  }

  .bold {
    font-weight: $boldFontWeight;
  }

  .box {
    border: 1px solid #eee;
    border-radius: 2px;
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0 0 16px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;

    &.box-borderless {
      border: none;
    }

    .box-section {
      padding: 16px; // 16px is same as antd collapse padding ('used in order detail page') to keep consisten ui

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }

    .store-map-wrapper {
      height: 100px;
      width: 100%;
    }
  }

  .refresh-button {
    cursor: pointer;
  }

  .separate-surcharge-container {
    .seperate-surcharge-sucharge-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .seperate-surcharge-pay-button {
      font-weight: bolder;
      border: 0;
      width: 100%;
      margin-top: 12px;
    }

    .seperate-surcharge-paid-message {
      margin-top: 5px;
    }

    .link {
      color: blue;
      cursor: pointer;
      outline: none;
      background-color: transparent;

      &:hover {
        color: darkblue;
      }
    }
  }

  .surcharge-paid-to-message-container {
    .link {
      color: blue;
      cursor: pointer;
      outline: none;
      background-color: transparent;

      &:hover {
        color: darkblue;
      }
    }
  }
}