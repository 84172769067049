body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.loading-page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 25px;
  margin: 0 50px; }


.content-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .content-container .login-form {
    width: 400px; }
  .content-container .logo-wrapper {
    text-align: center;
    padding: 30px 0; }
    .content-container .logo-wrapper .logo {
      width: 230px; }
  .content-container .auth-form-container .box {
    width: 350px;
    margin: auto;
    background: white;
    padding: 23px 26px; }
  .content-container .vc-input-wrapper {
    display: flex; }
    .content-container .vc-input-wrapper .vc-input-field {
      margin-right: 10px; }
    .content-container .vc-input-wrapper .login-page-phone-input {
      margin-right: 4px; }
    .content-container .vc-input-wrapper .login-page-get-vc-button {
      margin-left: 4px; }

.navbar-container {
  display: flex;
  width: 100% !important;
  max-height: 100%;
  justify-content: space-between;
  background-color: #f9f9f9 !important;
  padding-bottom: 2px !important; }
  .navbar-container .sticky {
    position: fixed;
    top: 0;
    width: 100%; }
  .navbar-container .width {
    width: 200px; }
  .navbar-container .logo-wrapper {
    position: relative; }
    .navbar-container .logo-wrapper:hover {
      cursor: pointer; }
    .navbar-container .logo-wrapper .logo {
      height: 100%;
      object-fit: contain;
      margin-left: 15px; }
    .navbar-container .logo-wrapper .logo-text {
      color: #0089dc;
      font-weight: 600;
      font-size: 25px; }
  .navbar-container .logout {
    color: white; }
  .navbar-container .ant-menu-horizontal {
    border-bottom-color: transparent;
    color: #707070; }
    .navbar-container .ant-menu-horizontal .ant-menu-submenu {
      border-bottom-color: transparent;
      color: #707070 !important; }
    .navbar-container .ant-menu-horizontal .ant-menu-item,
    .navbar-container .ant-menu-horizontal .ant-menu-submenu,
    .navbar-container .ant-menu-horizontal .ant-menu-submenu-open,
    .navbar-container .ant-menu-horizontal .ant-menu-submenu-active,
    .navbar-container .ant-menu-horizontal .ant-menu-submenu-title {
      color: #707070 !important; }
      .navbar-container .ant-menu-horizontal .ant-menu-item:hover,
      .navbar-container .ant-menu-horizontal .ant-menu-submenu:hover,
      .navbar-container .ant-menu-horizontal .ant-menu-submenu-open:hover,
      .navbar-container .ant-menu-horizontal .ant-menu-submenu-active:hover,
      .navbar-container .ant-menu-horizontal .ant-menu-submenu-title:hover {
        color: #707070 !important;
        border-bottom-color: transparent; }

.myaccount-language {
  color: #707070; }
  .myaccount-language:hover {
    color: #1890ff; }

.myaccount-selection {
  color: #707070; }
  .myaccount-selection:hover {
    color: #1890ff; }

.myaccount-sidebar-container .ant-menu-item {
  color: #707070; }
  .myaccount-sidebar-container .ant-menu-item:hover {
    color: #1890ff; }

.myaccount-sidebar-container .ant-menu-item {
  color: #707070; }
  .myaccount-sidebar-container .ant-menu-item:hover {
    color: #1890ff; }

.myaccount-sidebar-container .ant-menu-submenu .ant-menu-submenu-title {
  color: #707070; }
  .myaccount-sidebar-container .ant-menu-submenu .ant-menu-submenu-title:hover {
    color: #1890ff; }

.myaccount-sidebar-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(24, 144, 255, 0.178);
  color: #707070; }

.myaccount-sidebar-container .ant-menu-vertical .ant-menu-item:after,
.myaccount-sidebar-container .ant-menu-vertical-left .ant-menu-item:after,
.myaccount-sidebar-container .ant-menu-vertical-right .ant-menu-item:after,
.myaccount-sidebar-container .ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid #1890ff; }

.myaccount-sidebar-container .ant-menu-submenu-arrow:hover {
  color: #1890ff !important; }

.account-content-wrapper {
  margin: auto;
  display: flex; }
  .account-content-wrapper .user-section {
    padding: 5px 15px; }
    .account-content-wrapper .user-section:last-child {
      border-left: 1px solid #eee; }
  .account-content-wrapper .user-left-section {
    display: flex;
    flex: 1 1; }
    .account-content-wrapper .user-left-section .user-info-header {
      display: flex; }
    .account-content-wrapper .user-left-section .user-info-body {
      flex: 1 1;
      padding-left: 15px; }
      .account-content-wrapper .user-left-section .user-info-body .name {
        font-size: 18px;
        font-weight: 600; }
    .account-content-wrapper .user-left-section .user-info-footer {
      display: flex; }
      .account-content-wrapper .user-left-section .user-info-footer .edit-icon {
        cursor: pointer;
        font-size: 20px;
        color: '#333333'; }
  .account-content-wrapper .user-right-section {
    flex: 0.6 1;
    flex-direction: column;
    align-items: center; }
    .account-content-wrapper .user-right-section .order-item {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 5px;
      border-radius: 5px;
      cursor: pointer; }
      .account-content-wrapper .user-right-section .order-item:not(:last-child) {
        border-bottom: 1px solid #eee; }
      .account-content-wrapper .user-right-section .order-item:hover {
        background-color: #e6f7ff; }
      .account-content-wrapper .user-right-section .order-item .order-item-header {
        flex: 0.7 1; }
      .account-content-wrapper .user-right-section .order-item .order-item-body {
        flex: 2 1; }
      .account-content-wrapper .user-right-section .order-item .order-item-footer {
        flex: 1 1;
        text-align: right; }
    .account-content-wrapper .user-right-section .no-orders-wrapper {
      text-align: center; }
      .account-content-wrapper .user-right-section .no-orders-wrapper .text {
        padding-bottom: 7px; }
    .account-content-wrapper .user-right-section .view-all-btn {
      text-align: right;
      font-size: 12px; }
    .account-content-wrapper .user-right-section .action-btn {
      width: 80%;
      margin-bottom: 12px; }

.vc-input-wrapper {
  display: flex; }
  .vc-input-wrapper .vc-input-field {
    margin-right: 10px; }

.order-detail-control-panel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0; }
  .order-detail-control-panel-container .count-down-timer-timer-text {
    font-weight: bolder;
    color: #ffa500; }
  .order-detail-control-panel-container .pay-now-descrption-text {
    font-size: 15px;
    padding-right: 8px; }

.control-panel-button {
  margin-right: 5px; }
  .control-panel-button:last-child {
    margin-right: 0px; }

.order-detail-page-header-container {
  justify-content: space-between;
  align-items: center; }
  .order-detail-page-header-container .header-text-title {
    font-weight: bolder;
    font-size: 22px; }

.order-detail-content-container .tooltip {
  position: absolute;
  right: 74px;
  top: 180px; }

.order-detail-content-container .content-header {
  padding: 5px 15px; }
  .order-detail-content-container .content-header .status-wrapper {
    padding: 5px 40px 20px 40px; }
  .order-detail-content-container .content-header .special-status {
    text-align: right;
    font-size: 25px; }
    .order-detail-content-container .content-header .special-status.cancel {
      color: red; }

.order-detail-content-container .content-body {
  display: flex; }
  .order-detail-content-container .content-body .section-wrapper {
    padding: 5px 15px; }
    .order-detail-content-container .content-body .section-wrapper.left-section-wrapper {
      flex: 1 1; }
    .order-detail-content-container .content-body .section-wrapper.right-section-wrapper {
      min-width: 300px;
      flex: 0.45 1; }
    .order-detail-content-container .content-body .section-wrapper .store-name {
      display: inline-block; }
  .order-detail-content-container .content-body .order-items-wrapper .order-item-header {
    display: flex; }
    .order-detail-content-container .content-body .order-items-wrapper .order-item-header .item-price {
      text-align: right; }
  .order-detail-content-container .content-body .order-items-wrapper .totals-wrapper .totals-item {
    text-align: right;
    display: flex; }
    .order-detail-content-container .content-body .order-items-wrapper .totals-wrapper .totals-item.large {
      font-size: 17px;
      font-weight: 700; }
    .order-detail-content-container .content-body .order-items-wrapper .totals-wrapper .totals-item .totals-name {
      flex: 1 1; }
      .order-detail-content-container .content-body .order-items-wrapper .totals-wrapper .totals-item .totals-name.light {
        color: rgba(0, 0, 0, 0.45); }
    .order-detail-content-container .content-body .order-items-wrapper .totals-wrapper .totals-item .totals-price {
      width: 140px; }
      .order-detail-content-container .content-body .order-items-wrapper .totals-wrapper .totals-item .totals-price .large {
        font-weight: 700; }
  .order-detail-content-container .content-body .messages-wrapper {
    max-height: 40vh;
    overflow: auto; }
    .order-detail-content-container .content-body .messages-wrapper .message-wrapper {
      display: flex;
      margin: 6px 0;
      flex-direction: column; }
      .order-detail-content-container .content-body .messages-wrapper .message-wrapper.store {
        align-items: flex-start; }
        .order-detail-content-container .content-body .messages-wrapper .message-wrapper.store .message {
          border-radius: 0 8px 8px 8px;
          background: #f1f1f1; }
      .order-detail-content-container .content-body .messages-wrapper .message-wrapper.customer {
        align-items: flex-end; }
        .order-detail-content-container .content-body .messages-wrapper .message-wrapper.customer .message {
          border-radius: 8px 0px 8px 8px;
          color: white;
          background: #1e89e0;
          background: linear-gradient(90deg, #1e89e0 0%, #50a6ec 100%); }
      .order-detail-content-container .content-body .messages-wrapper .message-wrapper .message {
        max-width: 75%;
        padding: 8px 10px;
        border: 1px solid #eee;
        font-weight: 500; }
      .order-detail-content-container .content-body .messages-wrapper .message-wrapper .date {
        font-size: 10px; }

.order-detail-content-container .bold {
  font-weight: 700; }

.order-detail-content-container .box {
  border: 1px solid #eee;
  border-radius: 2px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 16px;
  transition: all 0.2s; }
  .order-detail-content-container .box.box-borderless {
    border: none; }
  .order-detail-content-container .box .box-section {
    padding: 16px; }
    .order-detail-content-container .box .box-section:not(:last-child) {
      border-bottom: 1px solid #eee; }
  .order-detail-content-container .box .store-map-wrapper {
    height: 100px;
    width: 100%; }

.order-detail-content-container .refresh-button {
  cursor: pointer; }

.order-detail-content-container .separate-surcharge-container .seperate-surcharge-sucharge-info {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.order-detail-content-container .separate-surcharge-container .seperate-surcharge-pay-button {
  font-weight: bolder;
  border: 0;
  width: 100%;
  margin-top: 12px; }

.order-detail-content-container .separate-surcharge-container .seperate-surcharge-paid-message {
  margin-top: 5px; }

.order-detail-content-container .separate-surcharge-container .link {
  color: blue;
  cursor: pointer;
  outline: none;
  background-color: transparent; }
  .order-detail-content-container .separate-surcharge-container .link:hover {
    color: darkblue; }

.order-detail-content-container .surcharge-paid-to-message-container .link {
  color: blue;
  cursor: pointer;
  outline: none;
  background-color: transparent; }
  .order-detail-content-container .surcharge-paid-to-message-container .link:hover {
    color: darkblue; }

.bookmarks-content-container {
  display: flex; }
  .bookmarks-content-container .bookmark-section-container {
    flex: 1 1;
    padding: 0 15px; }
    .bookmarks-content-container .bookmark-section-container:last-child {
      border-left: 1px solid #eee; }
    .bookmarks-content-container .bookmark-section-container .bookmark-list-wrapper .avatar-wrapper {
      width: 60px;
      height: 60px; }
      .bookmarks-content-container .bookmark-section-container .bookmark-list-wrapper .avatar-wrapper img {
        object-fit: contain; }
    .bookmarks-content-container .bookmark-section-container .bookmark-list-wrapper .rating-wrapper {
      font-size: 15px; }
      .bookmarks-content-container .bookmark-section-container .bookmark-list-wrapper .rating-wrapper .ant-rate-star {
        margin-right: 4px; }

.gift-card-row {
  padding: 10px 0;
  display: flex !important;
  justify-content: center; }
  .gift-card-row .gift-card-card {
    border-radius: 16px;
    box-shadow: 6px 9px 13px 0px #8c8c8c; }
  .gift-card-row .gift-card-info {
    cursor: pointer; }
  .gift-card-row .selected-card-display-row {
    display: flex;
    justify-content: flex-end;
    -webkit-align-content: flex-end;
    align-items: flex-end;
    height: 66px; }
    .gift-card-row .selected-card-display-row .selected {
      color: #1890ff; }
    .gift-card-row .selected-card-display-row .checked-box {
      font-size: 23px; }
  .gift-card-row .gift-card-wrapper {
    border: 1px solid #eee; }
  .gift-card-row .gift-card-col {
    padding: 0 !important;
    margin: 0 8px !important;
    width: 30.166667% !important; }
  .gift-card-row .selectable {
    cursor: pointer; }
  .gift-card-row .gift-card-action-btn {
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    color: rgba(0, 0, 0, 0.45);
    border: 1px solid #e8e8e8;
    border-top: none;
    cursor: pointer; }
    .gift-card-row .gift-card-action-btn:hover {
      color: #1890ff;
      background: #eeeeeee0; }

.gift-card-detail-content .gift-card-detail-top {
  display: flex;
  width: 100%;
  margin-bottom: 15px; }
  .gift-card-detail-content .gift-card-detail-top .general-info-wrapper {
    flex: 0.7 1;
    padding: 15px; }
  .gift-card-detail-content .gift-card-detail-top .transfer-form-wrapper {
    flex: 1 1;
    border-left: 1px solid #eee;
    padding: 15px; }


.address-item-wrapper {
  border: 1px solid #afafaf;
  padding: 20px;
  width: calc(33% - 18px);
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 10px;
  height: 250px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer; }
  .address-item-wrapper.add-address-btn-wrapper {
    border: 1px dashed #afafaf; }
    .address-item-wrapper.add-address-btn-wrapper .add-address-btn-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%; }
  .address-item-wrapper .address-item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .address-item-wrapper div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.map-search-container {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .map-search-container .map-container {
    flex: 1 1; }
  .map-search-container .search-bar {
    width: 100%; }

.currentLocationButton {
  cursor: pointer;
  font-weight: bolder;
  color: black;
  position: fixed;
  border-radius: 100px;
  background-color: white;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  max-width: 180px;
  top: 135px;
  right: 184px;
  z-index: 999;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12); }
  .currentLocationButton div {
    margin: 0 6px; }
  .currentLocationButton .currentLocationIcon {
    display: flex;
    align-items: center; }
  .currentLocationButton .currentLocationText {
    margin-bottom: 0px; }

.map-search-bar-place-holder {
  display: flex;
  justify-content: space-between; }
  .map-search-bar-place-holder .map-search-bar-place-holder-google-text {
    font-size: 12px; }

.address-form-switch-row {
  display: flex;
  align-items: center; }

.map-drawer-conatiner .ant-drawer-wrapper-body {
  height: 100vh !important;
  display: flex;
  flex-direction: column; }
  .map-drawer-conatiner .ant-drawer-wrapper-body .ant-drawer-body {
    flex: 1 1;
    display: flex;
    flex-direction: column; }
    .map-drawer-conatiner .ant-drawer-wrapper-body .ant-drawer-body .map-search-confirm-btn {
      margin: 20px 0; }

.address-item-wrapper {
  border: 1px solid #afafaf;
  padding: 20px;
  width: calc(45% - 18px);
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 10px;
  height: 195px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer; }
  .address-item-wrapper.add-address-btn-wrapper {
    border: 1px dashed #afafaf; }
    .address-item-wrapper.add-address-btn-wrapper .add-address-btn-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%; }
  .address-item-wrapper .address-item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .address-item-wrapper .address-item-content .address-actions {
      position: absolute;
      top: 143px; }
  .address-item-wrapper div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .address-item-wrapper.selected {
    border: 3px solid #1c89e0; }

.names-modal-container .missing-item {
  border: 1px solid #fc0048; }

.names-modal-container .missing-item-message {
  color: #fc0048;
  margin-bottom: 10px;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-content: center; }

.phone-modal-container .missing-item {
  border: 1px solid #fc0048; }

.phone-modal-container .missing-item-message {
  color: #fc0048;
  margin-bottom: 10px;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-content: center; }

.phone-modal-container .item {
  display: inline;
  width: 100%; }

.floating-input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 0.6vw 2vw 1.7vw 2vw;
  margin: -0.2vw 0vw 0vw 0vw;
  box-sizing: border-box; }
  .floating-input-container .input-border-wrapper {
    position: relative; }
    .floating-input-container .input-border-wrapper .input-border {
      position: absolute;
      transition: all 0.3s;
      width: 100%;
      -webkit-animation: input-border-default 0.2s;
              animation: input-border-default 0.2s;
      height: 1px; }
      .floating-input-container .input-border-wrapper .input-border.active {
        -webkit-animation: input-border-float 0.2s;
                animation: input-border-float 0.2s;
        height: 2px; }
  .floating-input-container .floating-input {
    padding: 0.0vw 1.5vw 0.0vw 0vw;
    margin: 0.0vw 0vw 0.2vw 0vw;
    height: 2rem;
    transition: all 0.3s;
    touch-action: manipulation;
    font-size: 1em;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    color: #615d5c; }
    .floating-input-container .floating-input:disabled {
      background: #e6e6e6;
      color: #615d5c;
      opacity: 0.8; }
    .floating-input-container .floating-input:disabled.no-disable-highlight {
      color: #615d5c;
      -webkit-text-fill-color: #615d5c;
      opacity: 1;
      background: inherit; }
    .floating-input-container .floating-input.unfocused::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      opacity: 0; }
    .floating-input-container .floating-input.unfocused::-moz-placeholder {
      /* Firefox 19+ */
      opacity: 0; }
    .floating-input-container .floating-input.unfocused:-ms-input-placeholder {
      /* IE 10+ */
      opacity: 0; }
    .floating-input-container .floating-input.unfocused:-moz-placeholder {
      /* Firefox 18- */
      opacity: 0; }
  .floating-input-container .label {
    -webkit-animation: floating-input-default 0.2s;
            animation: floating-input-default 0.2s;
    padding: 0vw 1.5vw 0vw 0vw;
    cursor: auto;
    touch-action: manipulation;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0vw, 1.9rem) scale(1);
    -webkit-transform: translate(0vw, 1.9rem) scale(1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    text-align: left; }
    .floating-input-container .label.floating {
      margin-top: 0.0em;
      transform: translate(0vw, 0vw) scale(0.7);
      -webkit-transform: translate(0vw, 0vw) scale(0.7);
      -webkit-animation: floating-input-float 0.2s;
              animation: floating-input-float 0.2s; }
  .floating-input-container .errorMsg {
    margin-top: 1vw; }

@-webkit-keyframes floating-input-default {
  0% {
    transform: translate(0vw, 0vw) scale(0.7); }
  100% {
    transform: translate(0vw, 1.9rem) scale(1); } }

@keyframes floating-input-default {
  0% {
    transform: translate(0vw, 0vw) scale(0.7); }
  100% {
    transform: translate(0vw, 1.9rem) scale(1); } }

@-webkit-keyframes floating-input-float {
  0% {
    transform: translate(0vw, 1.9rem) scale(1); }
  100% {
    transform: translate(0vw, 0vw) scale(0.7); } }

@keyframes floating-input-float {
  0% {
    transform: translate(0vw, 1.9rem) scale(1); }
  100% {
    transform: translate(0vw, 0vw) scale(0.7); } }

@-webkit-keyframes input-border-default {
  0% {
    height: 2px; }
  100% {
    height: 1px; } }

@keyframes input-border-default {
  0% {
    height: 2px; }
  100% {
    height: 1px; } }

@-webkit-keyframes input-border-float {
  0% {
    height: 1px; }
  100% {
    height: 2px; } }

@keyframes input-border-float {
  0% {
    height: 1px; }
  100% {
    height: 2px; } }

.phone .floating-input-container {
  position: relative;
  width: 104%;
  display: flex;
  flex-direction: column-reverse;
  padding: 0vw 2vw 1.9vw 2vw;
  margin: 0vw 0vw 0vw 0vw;
  box-sizing: border-box; }
  .phone .floating-input-container .input-border-wrapper {
    position: relative; }
    .phone .floating-input-container .input-border-wrapper .input-border {
      position: absolute;
      transition: all 0.3s;
      width: 100%;
      -webkit-animation: input-border-default 0.2s;
              animation: input-border-default 0.2s;
      height: 1px; }
      .phone .floating-input-container .input-border-wrapper .input-border.active {
        -webkit-animation: input-border-float 0.2s;
                animation: input-border-float 0.2s;
        height: 2px; }
  .phone .floating-input-container .ant-col-12 {
    padding-left: 0px !important;
    padding-right: -10px !important;
    margin-right: -10px !important;
    width: 100px; }
    .phone .floating-input-container .ant-col-12 .select-style {
      width: 90px;
      padding-right: 0px !important; }
      .phone .floating-input-container .ant-col-12 .select-style .ant-select-selection {
        border: none !important; }
  .phone .floating-input-container .floating-input {
    padding: 0.0vw 0vw 0.0vw 0vw;
    margin: 0.0vw 0vw 0.2vw 1vw;
    height: 2rem;
    transition: all 0.3s;
    touch-action: manipulation;
    font-size: 1em;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    color: #615d5c; }
    .phone .floating-input-container .floating-input:disabled {
      background: #e6e6e6;
      color: #615d5c;
      opacity: 0.8; }
    .phone .floating-input-container .floating-input:disabled.no-disable-highlight {
      color: #615d5c;
      -webkit-text-fill-color: #615d5c;
      opacity: 1;
      background: inherit; }
    .phone .floating-input-container .floating-input.unfocused::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      opacity: 0; }
    .phone .floating-input-container .floating-input.unfocused::-moz-placeholder {
      /* Firefox 19+ */
      opacity: 0; }
    .phone .floating-input-container .floating-input.unfocused:-ms-input-placeholder {
      /* IE 10+ */
      opacity: 0; }
    .phone .floating-input-container .floating-input.unfocused:-moz-placeholder {
      /* Firefox 18- */
      opacity: 0; }
  .phone .floating-input-container .phone-label .label {
    -webkit-animation: floating-input-default 0.2s;
            animation: floating-input-default 0.2s;
    padding: 0vw 1.5vw 0vw 0vw;
    cursor: auto;
    touch-action: manipulation;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0vw, 1.9rem) scale(1);
    -webkit-transform: translate(0vw, 1.9rem) scale(1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    text-align: left; }
    .phone .floating-input-container .phone-label .label.floating {
      margin-top: 0.0em;
      transform: translate(0vw, 0vw) scale(0.7);
      -webkit-transform: translate(0vw, 0vw) scale(0.7);
      -webkit-animation: floating-input-float 0.2s;
              animation: floating-input-float 0.2s; }
  .phone .floating-input-container .errorMsg {
    margin-top: 1vw; }

@-webkit-keyframes floating-input-default {
  0% {
    transform: translate(0vw, 0vw) scale(0.7); }
  100% {
    transform: translate(0vw, 1.9rem) scale(1); } }

@keyframes floating-input-default {
  0% {
    transform: translate(0vw, 0vw) scale(0.7); }
  100% {
    transform: translate(0vw, 1.9rem) scale(1); } }

@-webkit-keyframes floating-input-float {
  0% {
    transform: translate(0vw, 1.9rem) scale(1); }
  100% {
    transform: translate(0vw, 0vw) scale(0.7); } }

@keyframes floating-input-float {
  0% {
    transform: translate(0vw, 1.9rem) scale(1); }
  100% {
    transform: translate(0vw, 0vw) scale(0.7); } }

@-webkit-keyframes input-border-default {
  0% {
    height: 2px; }
  100% {
    height: 1px; } }

@keyframes input-border-default {
  0% {
    height: 2px; }
  100% {
    height: 1px; } }

@-webkit-keyframes input-border-float {
  0% {
    height: 1px; }
  100% {
    height: 2px; } }

@keyframes input-border-float {
  0% {
    height: 1px; }
  100% {
    height: 2px; } }

.customer-info-container {
  padding-top: 1px; }
  .customer-info-container .title {
    height: 100%;
    font-size: large;
    font-weight: 600;
    font-size: 16px; }
  .customer-info-container .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .customer-info-container .action {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .customer-info-container .list-information {
    padding: 0px 10px 10px 25px; }

.ant-modal-content {
  margin-bottom: -25px; }

.formItem {
  margin-bottom: 6px !important; }

.missing-item {
  border: 1px solid #fc0048; }

.missing-item-message {
  color: #fc0048;
  margin-bottom: 10px;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-content: center; }

.collapse-panel-container {
  background-color: #FAFAFA; }
  .collapse-panel-container .icon {
    display: flex;
    justify-content: center; }
  .collapse-panel-container .description {
    text-align: center;
    margin: 15px auto;
    display: flex;
    justify-content: center; }

.ant-collapse-content-box {
  background-color: #FAFAFA; }

.header-text {
  font-size: 16px; }

.header-img {
  margin: -5px 20px 0 15px;
  width: 35px; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0089dc; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0089dc;
  border-color: #0089dc; }

.giftCardModal .warnningMessage {
  color: #1C89E0;
  margin-bottom: 10px;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-content: center; }

.points-modal-container .current-value {
  width: 70%;
  font-size: 1.5em;
  font-weight: 600;
  display: inline-block; }

.points-modal-container .points-button {
  position: absolute;
  right: 30px;
  display: inline-block; }

.payment-info-container .title {
  font-size: large;
  font-weight: 600; }

.payment-info-container .content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.payment-info-container .checkout-billinginfo {
  font-weight: bold; }

.payment-info-container .action {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.payment-info-container .list-information {
  padding: 0px 10px 10px 25px; }

.shippingMethodModalContainer .radioItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%; }
  .shippingMethodModalContainer .radioItem .radioItemWrapper {
    width: 450px;
    margin: 5px 0; }

.shippingMethodModalContainer .descripTitle {
  margin: 5px 0;
  font-size: 15px;
  font-weight: 600; }

.payment-info-container {
  padding-bottom: 0.1vw; }
  .payment-info-container .title {
    height: 100%;
    font-size: large;
    font-weight: 600;
    font-size: 16px; }
  .payment-info-container .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .payment-info-container .action {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .payment-info-container .list-information {
    padding: 0px 10px 10px 25px; }
  .payment-info-container .checkout-shippinginfo {
    font-weight: bold; }
  .payment-info-container .section-form-row {
    display: flex;
    justify-content: flex-start;
    align-content: center; }
    .payment-info-container .section-form-row .section-form-col {
      display: flex;
      justify-content: flex-start;
      align-content: center; }
    .payment-info-container .section-form-row .align-center {
      align-items: center; }
    .payment-info-container .section-form-row .align-top {
      align-items: flex-start !important; }

.shipping-main-method .missing-item {
  border: 1px solid #fc0048; }

.shipping-main-method .missing-item-message {
  color: #fc0048;
  margin-bottom: 10px;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-content: center; }

.time-picker-modal {
  margin: -24px;
  display: flex; }
  .time-picker-modal .dateScrollMenu {
    width: 45%;
    border-right: 1px solid #e8e8e8;
    overflow: auto; }
    .time-picker-modal .dateScrollMenu .button-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 16px 12px;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      border-bottom: 1px solid #e8e8e8; }
      .time-picker-modal .dateScrollMenu .button-item .month {
        margin-left: 10px;
        font-weight: 700; }
      .time-picker-modal .dateScrollMenu .button-item .dateScrollMenu-date-button {
        width: 100%;
        border-radius: 0; }
        .time-picker-modal .dateScrollMenu .button-item .dateScrollMenu-date-button .top-text {
          font-size: 10px; }
        .time-picker-modal .dateScrollMenu .button-item .dateScrollMenu-date-button .bottom-text {
          font-weight: 900;
          font-size: 19px; }
        .time-picker-modal .dateScrollMenu .button-item .dateScrollMenu-date-button .single-line-text {
          font-weight: 900;
          font-size: 12px; }
    .time-picker-modal .dateScrollMenu .selected {
      background-color: #F2F2F2; }
    .time-picker-modal .dateScrollMenu .no-border {
      border: none; }
    .time-picker-modal .dateScrollMenu .invalidDate {
      border: 1px solid #fc0048; }
    .time-picker-modal .dateScrollMenu .inValidDateMessage {
      color: #fc0048;
      margin-bottom: 10px;
      font-size: 15px;
      display: flex;
      justify-content: flex-start;
      align-content: center; }
  .time-picker-modal .radio-row {
    padding: 16px 0;
    cursor: pointer; }
    .time-picker-modal .radio-row .opt-descript {
      font-weight: 500;
      font-size: 15px;
      padding-left: 20px; }
    .time-picker-modal .radio-row .radio-button {
      display: flex;
      justify-content: center;
      align-items: center; }
  .time-picker-modal .selected-time {
    background-color: #F2F2F2; }
  .time-picker-modal .radio-divider {
    margin: 0; }
  .time-picker-modal .date-time-divider {
    margin: 0; }

.shippingmethod-page-container {
  margin: 25px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 19px;
  font-weight: 500; }
  .shippingmethod-page-container .shipping-method-option-row {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .shippingmethod-page-container .shipping-method-option-row .option-time-picker-option-divider {
      font-weight: 300;
      margin: 0 10px;
      color: black; }
    .shippingmethod-page-container .shipping-method-option-row .option {
      margin-left: 7px;
      display: flex;
      align-items: center; }
      .shippingmethod-page-container .shipping-method-option-row .option:hover {
        cursor: pointer; }
    .shippingmethod-page-container .shipping-method-option-row .option-avaliable-reminder {
      color: #323358; }
      .shippingmethod-page-container .shipping-method-option-row .option-avaliable-reminder:hover {
        color: #323358; }
    .shippingmethod-page-container .shipping-method-option-row .option-avalable-reminder-icon {
      margin-left: 2px;
      color: #323358; }
      .shippingmethod-page-container .shipping-method-option-row .option-avalable-reminder-icon:hover {
        color: #323358; }
  .shippingmethod-page-container .delivery_option_error {
    width: 60px;
    padding-top: 20px;
    border-bottom: 1px solid #fc0048; }

.ant-modal.shipping-main-modal {
  top: 30%; }

.itemInfo .title {
  font-weight: bold;
  font-size: 11px;
  color: #666666; }

.itemInfo .border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.itemInfo .order-item-header {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-right: 0px !important;
  margin-left: 0px !important; }
  .itemInfo .order-item-header .item-name {
    padding-left: 0px !important; }
  .itemInfo .order-item-header .item-price {
    text-align: right;
    padding-right: 0px !important; }
  .itemInfo .order-item-header .item {
    padding-left: 3px !important;
    padding-right: 3px !important;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .itemInfo .order-item-header .single-item-price {
    text-align: right; }
  .itemInfo .order-item-header .cnt {
    text-align: right; }

.itemInfo .itemOpt {
  font-size: small;
  margin-left: 15px; }

.coupon-input {
  width: 100%; }

.price-info .totals-item {
  text-align: left;
  display: flex;
  justify-content: space-between; }
  .price-info .totals-item.large {
    font-size: 17px;
    font-weight: 700; }
  .price-info .totals-item .totals-name {
    flex: 1 1; }
    .price-info .totals-item .totals-name.light {
      color: rgba(0, 0, 0, 0.68); }
  .price-info .totals-item .totals-price {
    text-align: right;
    width: 140px; }
    .price-info .totals-item .totals-price .large {
      font-weight: 700; }
    .price-info .totals-item .totals-price .ant-input-number {
      background-color: transparent;
      margin-top: 4px;
      margin-right: -24px;
      padding: 0 25px 0 0;
      border: none;
      border-radius: 0px;
      height: 100%; }
    .price-info .totals-item .totals-price .ant-input-number-input {
      text-align: right;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-bottom: 1px solid #d9d9d9;
      border-radius: 0px;
      margin: 0;
      padding: 0; }

.price-info .tips-row {
  width: 90%;
  margin-top: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 1; }
  .price-info .tips-row .tips-button {
    width: 100%;
    font-size: 12px;
    height: 25px; }

@charset "UTF-8";
.checkout-page-container .missing {
  border: 1px solid #fc0048 !important;
  margin-left: -10px;
  padding-left: 10px; }

.checkout-page-container .row {
  display: flex;
  overflow: hidden;
  width: 100%; }

.checkout-page-container .row .left-col {
  display: table-cell;
  padding: 0px 30px 0px 24px; }
  .checkout-page-container .row .left-col .section-title {
    font-weight: 500;
    font-size: 20px;
    margin-top: 25px;
    display: flex; }
    .checkout-page-container .row .left-col .section-title .delivery_option {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      padding: 5px 0 0 10px;
      color: rgba(0, 0, 0, 0.68); }
      .checkout-page-container .row .left-col .section-title .delivery_option:hover {
        color: #40a9ff; }
    .checkout-page-container .row .left-col .section-title .delivery_option_error {
      width: 60px;
      padding-top: 20px;
      border-bottom: 1px solid #fc0048; }
  .checkout-page-container .row .left-col .checkout-section-container {
    margin-bottom: 20px; }
    .checkout-page-container .row .left-col .checkout-section-container .floating-input-container {
      margin-bottom: -0.3vw; }
    .checkout-page-container .row .left-col .checkout-section-container .checkout-section {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      padding: 10px 0 10px 20px;
      border-bottom: 1px solid #e8e8e8; }
  .checkout-page-container .row .left-col .steps-bar {
    margin-top: 10px; }
    .checkout-page-container .row .left-col .steps-bar .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: #1890ff; }
    .checkout-page-container .row .left-col .steps-bar .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
      color: #1890ff; }
    .checkout-page-container .row .left-col .steps-bar .ant-steps-item-title:after {
      content: "";
      height: 1px;
      width: 150px;
      background: #e8e8e8;
      display: block;
      position: absolute;
      top: 16px;
      left: 100%; }
  .checkout-page-container .row .left-col .logo-wrapper {
    width: 100%;
    position: relative;
    height: 64px; }
    .checkout-page-container .row .left-col .logo-wrapper:hover {
      cursor: pointer; }
    .checkout-page-container .row .left-col .logo-wrapper:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50%;
      width: 1px;
      background: #dad0d0a6; }
    .checkout-page-container .row .left-col .logo-wrapper .logo {
      height: 70%;
      object-fit: contain; }
    .checkout-page-container .row .left-col .logo-wrapper .logo-text {
      color: #1c89e0;
      font-weight: 600;
      font-size: 25px; }

.checkout-page-container .row .right-col {
  background-color: #fafafa;
  border: 2px solid #f2f2f2;
  box-shadow: -2px -1px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 28px 29px;
  width: 45%; }
  .checkout-page-container .row .right-col – .ant-divider-horizontal {
    display: block;
    height: 1px;
    width: 100%;
    min-width: 100%;
    margin: 22px 0 !important;
    clear: both; }

.checkout-page-container .checkout-disclaimer {
  font-size: 0.8em;
  padding: 5px 0 10px 0; }
  .checkout-page-container .checkout-disclaimer span {
    font-weight: 600;
    color: #0089dc; }
    .checkout-page-container .checkout-disclaimer span:hover {
      cursor: pointer; }

.checkout-page-container .transaction {
  text-align: right;
  font-size: 0.8em;
  color: #333333;
  padding-top: 5px;
  margin-bottom: -15px; }

.checkout-page-container .change_button {
  box-shadow: none;
  border-color: transparent;
  color: #40a9ff; }
  .checkout-page-container .change_button:hover {
    color: #33658f;
    background-color: #fff;
    border-color: transparent; }
  .checkout-page-container .change_button:focus {
    color: #40a9ff;
    background-color: #fff;
    border-color: transparent; }

.checkout-page-container .secure-inner-wrapper {
  font-size: 12px;
  color: #6d6d6d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 7.5px;
  margin-top: 5px; }

.content-container {
  display: flex;
  flex-direction: column; }
  .content-container .logo-wrapper {
    text-align: center;
    padding: 30px 0; }
    .content-container .logo-wrapper .logo {
      width: 230px; }
  .content-container .auth-form-container {
    background-color: #ffffff; }
    .content-container .auth-form-container .box {
      width: 100%;
      margin: auto;
      background: white;
      padding: 20px 0px 20px 0px; }
      .content-container .auth-form-container .box .link {
        margin-top: -25px;
        margin-bottom: 15px; }
      .content-container .auth-form-container .box .ant-input-group .ant-input {
        float: left;
        margin-bottom: 0; }
        .content-container .auth-form-container .box .ant-input-group .ant-input .ant-input-lg {
          margin-bottom: 0; }
      .content-container .auth-form-container .box .ant-modal-body {
        padding: 0 -5px 0 -5px; }
      .content-container .auth-form-container .box .mediaCol {
        text-align: center; }
      .content-container .auth-form-container .box .login-icon {
        margin-right: 4px; }
      .content-container .auth-form-container .box .vc-input-wrapper {
        display: flex; }
        .content-container .auth-form-container .box .vc-input-wrapper .vc-input-field {
          margin-right: 10px; }
      .content-container .auth-form-container .box .ant-layout-content {
        height: 400px; }
      .content-container .auth-form-container .box .alt-login-intro {
        text-align: center;
        margin-top: -10px;
        margin-bottom: 16px;
        margin-left: auto;
        margin-right: auto; }
      .content-container .auth-form-container .box .input-spacing-top {
        margin-top: -8px; }
      .content-container .auth-form-container .box .vc-input-wrapper {
        margin-top: -8px; }
        .content-container .auth-form-container .box .vc-input-wrapper .phone-input {
          width: 160px; }
        .content-container .auth-form-container .box .vc-input-wrapper .ver-button {
          width: 150px;
          padding: 0 5px 0;
          margin-left: 10px;
          text-align: center;
          flex: 1 1; }
      .content-container .auth-form-container .box .login-form-button {
        margin: -6px 0 -7px 0; }
      .content-container .auth-form-container .box .ant-form-item-control {
        line-height: 0px; }
      .content-container .auth-form-container .box .missing-item-message {
        margin-top: 10px; }


.topbar-container {
  display: flex;
  max-height: 100%;
  margin: auto 0;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15); }

.top-bar-submenu-title {
  color: #707070;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 35px; }

.top-bar-submenu-title:hover {
  color: #2bb8aa; }

.top-bar-submenuContainer {
  max-height: 100% !important;
  max-width: 100% !important;
  margin: auto 0;
  padding: 1px 7px !important; }

.top-bar-submenuQR {
  height: 210px !important;
  max-width: 100% !important;
  margin: auto 0;
  padding: 5px !important; }

.top-bar-subItems {
  color: #707070; }

.top-bar-subItems:hover {
  color: #2bb8aa; }

.top-bar-qrImg {
  width: auto;
  width: initial;
  height: 200px; }

.top-bar-chooseCity {
  width: 130px;
  border-right: 1px solid rgba(171, 171, 172, 0.15) !important; }

.top-bar-LogIn {
  margin-left: 30px;
  width: 50px; }

.top-bar-SignUp {
  margin-right: 10px;
  width: 65px;
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }

.top-bar-emptySpace {
  width: 200px;
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }

.top-bar-app {
  width: 80px;
  border-left: 1px solid rgba(218, 218, 218, 0.15) !important;
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }
  .top-bar-app .ant-menu-vertical {
    padding: 10px;
    width: 480px; }

.top-bar-User {
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }

.top-bar-history {
  width: 95px;
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }

.top-bar-service {
  width: 98px;
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }

.top-bar-business {
  width: 110px;
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }

.top-bar-favorite {
  padding-left: 15px;
  width: 100px;
  border-right: 1px solid rgba(218, 218, 218, 0.15) !important; }

.group-navbar-container {
  width: 100%;
  margin: auto 0;
  justify-content: center;
  align-items: center;
  background: white; }
  .group-navbar-container .nav-bar-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: auto 0;
    align-items: center;
    margin-bottom: 10%; }
    .group-navbar-container .nav-bar-container .logo-wrapper {
      width: 200px;
      position: relative;
      margin-right: 30px; }
      .group-navbar-container .nav-bar-container .logo-wrapper:hover {
        cursor: pointer; }
      .group-navbar-container .nav-bar-container .logo-wrapper:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 50%;
        width: 1px;
        background: #efefefa6; }
      .group-navbar-container .nav-bar-container .logo-wrapper .logo {
        width: 200px;
        height: 70%;
        object-fit: contain; }
      .group-navbar-container .nav-bar-container .logo-wrapper .logo-text {
        color: white;
        font-weight: 600;
        font-size: 20px; }
      .group-navbar-container .nav-bar-container .logo-wrapper .search-box {
        width: 180px; }
    .group-navbar-container .nav-bar-container .logout {
      color: white; }
    .group-navbar-container .nav-bar-container .ant-menu-horizontal {
      border-bottom-color: transparent;
      color: #ffffff; }
      .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu {
        border-bottom-color: transparent; }
      .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-item, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu-open, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu-active, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu-title {
        color: #ffffff !important; }
        .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-item:hover, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu:hover, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu-open:hover, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu-active:hover, .group-navbar-container .nav-bar-container .ant-menu-horizontal .ant-menu-submenu-title:hover {
          color: #ffffff !important;
          border-bottom-color: transparent; }
    .group-navbar-container .nav-bar-container .ant-input-wrapper ant-input-group {
      margin: 10px; }

.nav-bar-searchButton {
  margin-right: 0px;
  background-color: #fc5f00 !important; }

.nav-bar-searchButton:hover,
.nav-bar-searchButton:focus {
  color: #fff;
  background-color: #fc7d2f !important;
  border-color: #fc7d2f !important; }

.top-menu-container {
  display: flex;
  max-height: 100%;
  margin: auto 0;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  margin-top: 50px; }
  .top-menu-container .top-menu-Selection {
    color: white !important;
    height: 2.85rem;
    padding: 0 2.6rem;
    margin: 0 -1.2rem; }
  .top-menu-container .top-menu-Selection:hover {
    color: white !important;
    width: 300px !important;
    background-color: #00c3b0;
    padding: .65rem 2.6rem; }
  .top-menu-container .top-menu-longSelection {
    color: white !important;
    height: 2.85rem;
    padding: 0 1.6rem;
    margin: 0 -1.2rem; }
  .top-menu-container .top-menu-longSelection:hover {
    color: white !important;
    background-color: #00c3b0;
    padding: .65rem 1.6rem; }

.ads-container {
  position: relative;
  display: inline-block;
  justify-content: center;
  width: 970px;
  height: 90px;
  margin: 22px auto auto auto; }
  .ads-container .close-button {
    position: relative;
    z-index: 2;
    bottom: 430px;
    left: 955px;
    color: black;
    cursor: pointer; }

.group-sale-cell-container {
  border-bottom: 1px #E6E7E7 solid;
  cursor: pointer; }
  .group-sale-cell-container .img-parent {
    border-radius: 8px;
    height: 230px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: inline-block;
    cursor: pointer; }
  .group-sale-cell-container .img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .5s;
    overflow: hidden; }
  .group-sale-cell-container .img:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52, 73, 94, 0.75); }
  .group-sale-cell-container:hover {
    border-bottom: 1px #727578 solid; }
  .group-sale-cell-container:hover .img,
  .group-sale-cell-container:focus .img {
    transform: scale(1.1); }
  .group-sale-cell-container:hover .img:before,
  .group-sale-cell-container:focus .img:before {
    display: block; }
  .group-sale-cell-container .name {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden; }
  .group-sale-cell-container .descript {
    color: #808080;
    white-space: nowrap;
    overflow: hidden; }
  .group-sale-cell-container .localsPrice {
    color: #808080;
    font-size: 13px;
    display: flex;
    justify-content: space-between; }
    .group-sale-cell-container .localsPrice .local {
      align-self: flex-end; }
    .group-sale-cell-container .localsPrice .oldPrice {
      color: black;
      text-decoration: line-through;
      font-size: 19px;
      font-weight: 400;
      margin: 0 3px; }
    .group-sale-cell-container .localsPrice .newPrice {
      margin-left: 2px;
      font-size: 19px;
      font-weight: 400;
      color: #2bb8aa; }
  .group-sale-cell-container .rateDiscount {
    color: #808080;
    font-size: 13px;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    line-height: 19px;
    margin-bottom: 12px; }
    .group-sale-cell-container .rateDiscount .rating-number {
      margin-right: 2px;
      align-self: flex-end;
      font-size: 13px; }
    .group-sale-cell-container .rateDiscount .rating-count {
      margin-left: -6px;
      font-size: 13px; }
  .group-sale-cell-container .price-discount {
    display: inline;
    color: #2bb8aa;
    text-align: center;
    vertical-align: middle;
    padding: 1px 12px;
    background-color: #F2F2F4; }
  .group-sale-cell-container .ant-rate-star {
    margin-right: 3px; }

.category-list-bar-container {
  margin-top: 20px;
  margin-bottom: 57px; }
  .category-list-bar-container .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #343434;
    margin: 15px 0px; }
  .category-list-bar-container .list {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .category-list-bar-container .list .item {
      margin: 0 auto;
      cursor: pointer; }
      .category-list-bar-container .list .item .icon-parent {
        margin: 0 27px;
        border-radius: 100%;
        font-size: 30px;
        width: 75px;
        height: 75px;
        display: flex;
        overflow: hidden;
        position: relative;
        cursor: pointer; }
      .category-list-bar-container .list .item .icon {
        padding: 15px;
        margin-left: .1em;
        margin-top: 3px;
        height: 90%;
        width: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .5s;
        overflow: hidden; }
      .category-list-bar-container .list .item .icon:before {
        content: "";
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .category-list-bar-container .list .item:hover .icon,
      .category-list-bar-container .list .item:focus .icon {
        background-color: #DCDCDC;
        transform: scale(1.2); }
      .category-list-bar-container .list .item:hover .icon:before,
      .category-list-bar-container .list .item:focus .icon:before {
        display: block; }
      .category-list-bar-container .list .item .name {
        width: 130px;
        text-align: center;
        font-size: 16px;
        font-weight: bold; }
  .category-list-bar-container .center {
    display: flex;
    justify-content: center;
    align-items: center; }

.group-sale-landing-container {
  margin: 0 60px; }
  .group-sale-landing-container .welcomeMessage {
    font-size: 25px;
    margin-bottom: 22px;
    margin-top: 20px; }
  .group-sale-landing-container .top-three-container {
    margin-bottom: 30px; }
  .group-sale-landing-container .listTitle {
    border-bottom: none;
    margin: 0;
    padding: 0;
    width: 100%;
    line-height: 25px;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #343434;
    letter-spacing: .4px; }
  .group-sale-landing-container .pop-list-container {
    margin-top: 30px;
    margin-bottom: 30px; }
  .group-sale-landing-container .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px; }
  .group-sale-landing-container .descript {
    margin-top: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .group-sale-landing-container .localsPrice {
    margin-top: 3px; }

.sort_button-container .sort_title {
  margin-right: 20px; }

.sort_button-container .icon {
  font-size: 13px; }

.menu {
  position: absolute;
  top: 13px;
  z-index: 10;
  background-color: #fff;
  padding: 0 0 16px 16px;
  width: 253px;
  border: 1px solid #dfe5e8;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.37);
  border-radius: 2px; }

.menu:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: -11px;
  right: 3%;
  transform: translateX(-50%) rotate(45deg);
  background-color: #fff;
  border-top: 1px solid #dfe5ec;
  border-left: 1px solid #dfe5ec;
  width: 19px;
  height: 19px;
  box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.1); }

.category-tree-list-container {
  color: #333;
  font-size: 14px; }
  .category-tree-list-container .cat-title {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 0;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #333;
    font-size: 23px;
    font-weight: 500; }
  .category-tree-list-container .cat-item {
    margin: 12px 0;
    cursor: pointer; }
  .category-tree-list-container .highlighted {
    font-weight: 600; }
  .category-tree-list-container .margin-left {
    margin-left: 15px; }
  .category-tree-list-container .left-padding {
    padding-left: 30px; }
  .category-tree-list-container .list {
    font-weight: 400; }

.category-filter-container {
  margin-top: 25px; }
  .category-filter-container .filter-title {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 0;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #333;
    font-size: 23px;
    font-weight: 500; }
  .category-filter-container .slider-input {
    margin: 25px 0;
    font-size: 15px; }
    .category-filter-container .slider-input .input {
      margin: 5px; }
  .category-filter-container .slider {
    margin: 21px 10px 10px 12px; }
  .category-filter-container .checkbox-item {
    margin: 5px 0;
    color: #333;
    font-size: 15px; }
  .category-filter-container .ant-slider-rail {
    height: 10px; }
  .category-filter-container .ant-slider-track {
    height: 10px; }
  .category-filter-container .ant-slider-handle {
    width: 25px;
    height: 25px; }

.large-group-sale-cell-container {
  border-bottom: 1px #E6E7E7 solid;
  margin-bottom: 16px;
  cursor: pointer; }
  .large-group-sale-cell-container .img-parent {
    height: 288px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: inline-block; }
  .large-group-sale-cell-container .img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .5s;
    overflow: hidden; }
  .large-group-sale-cell-container .img:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52, 73, 94, 0.75); }
  .large-group-sale-cell-container:hover {
    border-bottom: 1px #727578 solid; }
  .large-group-sale-cell-container:hover .img,
  .large-group-sale-cell-container:focus .img {
    transform: scale(1.1); }
  .large-group-sale-cell-container:hover .img:before,
  .large-group-sale-cell-container:focus .img:before {
    display: block; }
  .large-group-sale-cell-container .name {
    margin-top: 8px;
    margin-bottom: 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden; }
  .large-group-sale-cell-container .descript {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 13px;
    padding: 0;
    height: 80px;
    color: #727578 !important;
    font-weight: 400;
    margin: 0 0 20px 0;
    overflow: hidden; }
  .large-group-sale-cell-container .locals {
    color: #808080;
    font-size: 13px;
    display: flex;
    justify-content: flex-start; }
  .large-group-sale-cell-container .rateDiscount {
    color: #808080;
    font-size: 13px;
    margin: 2px 0;
    display: flex;
    justify-content: flex-start;
    line-height: 19px;
    margin-bottom: 12px; }
    .large-group-sale-cell-container .rateDiscount .rating-number {
      margin-right: 2px;
      align-self: flex-end;
      font-size: 13px; }
    .large-group-sale-cell-container .rateDiscount .rating-count {
      margin-left: -6px;
      font-size: 13px; }
  .large-group-sale-cell-container .localsPrice {
    color: #808080;
    font-size: 13px;
    display: flex;
    justify-content: flex-end; }
    .large-group-sale-cell-container .localsPrice .oldPrice {
      align-self: flex-end;
      color: #727578 !important;
      font-size: 13px;
      font-weight: 400;
      text-decoration: line-through;
      font-weight: 400;
      margin: 0 3px; }
    .large-group-sale-cell-container .localsPrice .newPrice {
      margin-left: 3px;
      font-size: 24px;
      font-weight: 600;
      color: #2bb8aa; }
  .large-group-sale-cell-container .price-discount-wrapper {
    display: flex;
    justify-content: flex-end; }
    .large-group-sale-cell-container .price-discount-wrapper .price-discount {
      text-align: right;
      border-radius: 3px;
      color: #2bb8aa;
      font-size: 12px;
      height: 20px;
      padding: 2px 8px;
      vertical-align: middle;
      display: inline;
      background-color: #F2F2F4; }
  .large-group-sale-cell-container .button-wrapper {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end; }
  .large-group-sale-cell-container .ant-rate-star {
    margin-right: 3px; }

.group-sale-search-container {
  margin: 0 auto;
  max-width: 1080px;
  color: #727578;
  font-size: 1.4rem;
  font-weight: 400; }
  .group-sale-search-container .pull-page-header {
    position: relative;
    border: none;
    margin: 16px 0; }
    .group-sale-search-container .pull-page-header .brower-header {
      display: flex;
      justify-content: space-between; }
      .group-sale-search-container .pull-page-header .brower-header .title {
        font-size: 35px;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 300; }
      .group-sale-search-container .pull-page-header .brower-header .sort {
        display: flex;
        justify-content: space-between;
        align-self: flex-end;
        background-color: white !important; }
      .group-sale-search-container .pull-page-header .brower-header .ant-btn {
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
        color: rgba(0, 0, 0, 0.65); }
      .group-sale-search-container .pull-page-header .brower-header .ant-btn:hover,
      .group-sale-search-container .pull-page-header .brower-header .ant-btn:focus {
        color: rgba(0, 0, 0, 0.65);
        background-color: #fff;
        border-color: #d9d9d9; }
  .group-sale-search-container .dotted-line {
    padding: 0;
    margin: 10px 0 0;
    height: 1px;
    width: 100%;
    border-bottom: black 1px dotted; }
  .group-sale-search-container .left-col {
    padding-right: 24px; }
    .group-sale-search-container .left-col .reset-all-container {
      padding-top: 8px;
      display: flex;
      justify-content: flex-start; }
      .group-sale-search-container .left-col .reset-all-container .reset-pill {
        border-radius: 2px;
        cursor: pointer;
        border-radius: 2px;
        display: flex;
        font-size: 14px;
        margin: 0 8px 8px 0;
        padding: 3px 8px 4px 12px;
        background-color: #fc5f00;
        color: white; }
        .group-sale-search-container .left-col .reset-all-container .reset-pill .content {
          margin-right: 2px;
          margin-left: 6px; }
        .group-sale-search-container .left-col .reset-all-container .reset-pill .icon {
          margin: auto 0; }
  .group-sale-search-container .right-col {
    padding-top: 10px; }
    .group-sale-search-container .right-col .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px; }

.picture-video-carousel-container .ant-carousel .slick-slide {
  border-radius: 10px;
  text-align: center;
  height: 412.2px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden; }

.picture-video-carousel-container .ant-carousel .slick-slide h3 {
  color: #fff; }

.picture-video-carousel-container .img {
  height: 412.2px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block; }

.picture-video-carousel-container .imgs-button-wrapper {
  left: 0;
  margin: 10px 0 0;
  min-width: 57px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start; }
  .picture-video-carousel-container .imgs-button-wrapper .img-small {
    border-radius: 6px;
    border: 0 solid;
    cursor: pointer;
    display: inline-block;
    height: 57px;
    margin: 0 10px 0 0;
    outline: 0 solid #fff;
    overflow: hidden;
    position: relative;
    transition: border-width 0.25s cubic-bezier(0.4, -1.5, 0.6, 2.75), outline-width 0.25s cubic-bezier(0.25, 2.5, 0.625, 0.25);
    width: 57px; }

.picture-video-carousel-container .selected-border {
  padding: 2px;
  border: 2px solid #0081e3 !important; }

.picture-video-carousel-container .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 57px;
  font-size: 44px;
  border: 1px solid #cccccc; }

.picture-video-carousel-container .icon-selected {
  margin: -4px; }

.picture-video-carousel-container:hover > .nav-arrows {
  display: block; }

.picture-video-carousel-container .nav-arrows {
  display: none;
  position: absolute;
  width: 100%;
  top: 204px;
  padding: 0 10px; }
  .picture-video-carousel-container .nav-arrows .button {
    z-index: 2;
    background: #333;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    height: 60px;
    line-height: 65px;
    pointer-events: all;
    text-align: center;
    top: 10.5%;
    width: 60px; }
    .picture-video-carousel-container .nav-arrows .button:hover {
      background: white;
      color: black; }
    .picture-video-carousel-container .nav-arrows .button .carousel-icon {
      margin: auto auto; }
  .picture-video-carousel-container .nav-arrows .prev {
    left: 10px; }
  .picture-video-carousel-container .nav-arrows .next {
    right: 30px; }

.review-cell-container {
  width: 100%; }
  .review-cell-container .user-icon {
    line-height: 42px;
    height: 42px;
    width: 42px; }
  .review-cell-container .user-text-container {
    position: relative;
    min-height: 1px;
    padding-right: 12px;
    float: left;
    display: block;
    clear: both;
    margin: 3px 0 4px;
    color: #727578;
    font-size: 12px; }
    .review-cell-container .user-text-container .reviewer-name {
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1.21; }
    .review-cell-container .user-text-container .user-stats {
      line-height: 20px; }
      .review-cell-container .user-text-container .user-stats .reviewer-review-count {
        width: 256px;
        height: 40px;
        text-align: left;
        color: #75787b;
        font-weight: 200; }
  .review-cell-container .ratings-date {
    width: 100%;
    position: relative;
    min-height: 1px;
    padding: 0 12px 0 0;
    float: left;
    margin: 8px 0; }
  .review-cell-container .review-text {
    width: 100%;
    max-height: 5.6em;
    overflow: hidden;
    position: relative;
    min-height: 1px;
    padding: 0 12px 0 0;
    line-height: 1.43;
    font-size: 1rem;
    font-weight: 100; }
  .review-cell-container .ant-rate-star {
    margin-right: 3px; }

.review-modal-content {
  padding-right: 10px; }

.ant-pagination {
  display: flex;
  justify-content: center;
  align-items: center; }

.content {
  padding-right: 10px; }

.ant-pagination {
  display: flex;
  justify-content: center;
  align-items: center; }

.img-parent {
  border-radius: 8px;
  height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer; }

.img {
  border-radius: 8px;
  color: #a5a8ab;
  object-fit: cover;
  overflow: hidden;
  height: auto;
  max-width: 100%;
  cursor: pointer; }

.content {
  display: flex;
  justify-content: center;
  padding-right: 10px; }

.title {
  font-size: 25px;
  margin-right: 5px; }

.button {
  background: #333;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  height: 60px;
  line-height: 65px;
  margin-top: -30px;
  pointer-events: all;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-content: center; }
  .button:hover {
    background: white;
    color: black; }
  .button .icon {
    margin: auto auto; }

.prev {
  left: 20px; }

.next {
  right: 20px; }

.select-items-container {
  margin: 20px 0 12px 0; }
  .select-items-container .breakout-pricing-messages {
    background: #fff;
    list-style-type: none;
    padding: 8px 0 20px 0;
    border-bottom: 1px solid #d5d8db; }
    .select-items-container .breakout-pricing-messages .price-discount {
      width: 50%;
      display: block;
      justify-content: flex-start; }
      .select-items-container .breakout-pricing-messages .price-discount .oldPrice {
        color: black;
        text-decoration: line-through;
        font-size: 19px;
        font-weight: 400;
        margin: 0 3px; }
      .select-items-container .breakout-pricing-messages .price-discount .newPrice {
        margin-left: 2px;
        font-size: 19px;
        font-weight: 400;
        color: #2bb8aa; }
      .select-items-container .breakout-pricing-messages .price-discount .discount-message-wrapper {
        margin: 2px 0 0;
        color: #2bb8aa;
        text-align: center;
        vertical-align: middle;
        padding: 1px 12px;
        background-color: #f2f2f4;
        font-size: 15px; }
    .select-items-container .breakout-pricing-messages .message {
      width: 50%; }
  .select-items-container .select-item-container {
    border-bottom: 1px solid #d5d8db;
    font-size: 1.2rem;
    margin-bottom: 20px;
    padding: 10px 0; }
    .select-items-container .select-item-container .missing-item-message {
      color: #fc0048;
      margin-bottom: 4px;
      font-size: 15px;
      display: flex;
      justify-content: flex-start;
      align-content: center; }
    .select-items-container .select-item-container .missing-item {
      border: 1px solid #fc0048; }
    .select-items-container .select-item-container .select-item {
      display: flex;
      justify-content: space-between;
      align-content: center;
      width: 100%; }
      .select-items-container .select-item-container .select-item .select-dropdown-nm {
        width: 75%; }
      .select-items-container .select-item-container .select-item .select-dropdown-pc {
        margin-left: 20px; }
      .select-items-container .select-item-container .select-item .select-dropdown-class {
        display: flex;
        justify-content: space-between; }
  .select-items-container .buy-button {
    margin: 8px 0; }
  .select-items-container .opt-name {
    margin: auto 0;
    font-weight: 600; }
  .select-items-container .ant-select-selection__placeholder {
    color: rgba(0, 0, 0, 0.65); }
  .select-items-container .ant-btn-primary {
    color: #fff !important;
    background-color: #2bb8aa !important;
    border-color: #2bb8aa !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }
  .select-items-container .ant-btn-primary:hover,
  .select-items-container .ant-btn-primary:focus {
    color: #fff;
    background-color: #00c3b0 !important;
    border-color: #00c3b0 !important; }

.store-info-container .merchant-header {
  display: flex;
  margin-bottom: 16px;
  min-height: 56px; }
  .store-info-container .merchant-header .icon-merchant {
    border: 1px solid #e6e7e8;
    font-size: 28px;
    line-height: 1;
    padding: 14px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .store-info-container .merchant-header .header-text {
    margin-left: 17px; }
    .store-info-container .merchant-header .header-text .store-name {
      line-height: 1.2;
      margin: 10px 0 5px 0;
      font-weight: 500;
      font-size: 20px;
      color: #333; }
    .store-info-container .merchant-header .header-text .store-services {
      color: #727578;
      font-size: 14px; }

.store-info-container .store-map-wrapper {
  height: 200px;
  width: 100%; }

.store-info-container .address-bar {
  align-items: center;
  border: solid;
  border-width: 0 1px 1px 1px;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -o-box-pack: justify;
  justify-content: space-between;
  min-height: 75px;
  padding: 10px 20px; }
  .store-info-container .address-bar .marker {
    margin-right: 20px;
    min-width: 26px; }
  .store-info-container .address-bar .address-area {
    flex-grow: 1; }
    .store-info-container .address-bar .address-area .address-top {
      display: flex;
      font-weight: bolder; }
    .store-info-container .address-bar .address-area .address-bottom {
      line-height: 1.5;
      font-size: 13px;
      font-weight: 300; }

.store-info-container .merchant-attribute {
  border: solid;
  border-width: 0 1px 1px;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -o-box-pack: justify;
  justify-content: space-between;
  padding: 10px; }
  .store-info-container .merchant-attribute .contact-info {
    margin-bottom: 0;
    padding: 0 7px;
    width: 33%;
    font-size: 14px; }
    .store-info-container .merchant-attribute .contact-info .contact-row {
      align-items: center;
      display: -ms-flexbox;
      display: box;
      display: flex;
      padding: 8px 0; }
  .store-info-container .merchant-attribute .merchant-hours {
    width: 67%; }
    .store-info-container .merchant-attribute .merchant-hours .merchant-status {
      display: flex;
      padding: 8px 0 4px 0; }
      .store-info-container .merchant-attribute .merchant-hours .merchant-status .mechant-icon {
        height: 19px;
        margin: 1px 12px 0 0; }
      .store-info-container .merchant-attribute .merchant-hours .merchant-status .mechant-status {
        font-weight: bolder;
        color: #2bb8aa;
        font-size: 18px; }
    .store-info-container .merchant-attribute .merchant-hours .open-hour {
      margin-left: 42px;
      font-size: 14px;
      display: flex; }
      .store-info-container .merchant-attribute .merchant-hours .open-hour .day {
        width: 15%; }
      .store-info-container .merchant-attribute .merchant-hours .open-hour .hour {
        width: 50%; }
    .store-info-container .merchant-attribute .merchant-hours .toggle {
      color: #2bb8aa;
      cursor: pointer;
      font-size: 15px;
      margin-left: 42px; }

.store-info-container .c-bdr-gray-lt {
  border-color: #d5d8db !important; }

.group-sale-details-container {
  margin: 0 auto;
  max-width: 1080px;
  padding-top: 20px;
  font-size: 1.1rem;
  position: relative; }
  .group-sale-details-container .header-section {
    margin-bottom: 15px; }
    .group-sale-details-container .header-section .title {
      font-size: 2.2rem;
      font-weight: 500;
      margin: 0 8px 0 0;
      line-height: 1.5;
      color: #333;
      align-items: center;
      display: flex;
      margin-bottom: 0.4rem;
      position: relative;
      letter-spacing: normal;
      line-height: normal; }
    .group-sale-details-container .header-section .location {
      color: #808080;
      font-size: 13px;
      line-height: 19px;
      align-items: center;
      display: flex;
      margin: 10px 0 10px 0;
      justify-content: flex-start; }
      .group-sale-details-container .header-section .location .icon {
        display: flex;
        font-size: 1.05rem;
        line-height: 1;
        margin-right: 0.4rem; }
  .group-sale-details-container .rate {
    color: #727578;
    font-weight: 400;
    display: table;
    padding-right: 5px;
    font-size: 0.9rem;
    line-height: 1.5;
    font-size: 19px;
    line-height: 18px; }
    .group-sale-details-container .rate .rating-stars {
      margin: 0 10px 2px 0;
      font-weight: 400; }
    .group-sale-details-container .rate .rating-numbers {
      color: #0081e3;
      font-weight: 400;
      font-size: 0.9rem; }
    .group-sale-details-container .rate .rating-count {
      color: #0081e3;
      font-size: 0.9rem; }
  .group-sale-details-container .left-col {
    border-right: 1px solid #e6e7e8;
    margin-right: 0;
    padding-right: 20px; }
    .group-sale-details-container .left-col .write-up {
      overflow: visible;
      position: relative;
      padding-top: 30px;
      margin-bottom: 20px; }
      .group-sale-details-container .left-col .write-up .write-up-title {
        font-size: 1.8rem;
        font-weight: 700;
        color: #333;
        margin: 10px 0; }
      .group-sale-details-container .left-col .write-up .p {
        word-wrap: break-word;
        margin: 0 0 20px; }
      .group-sale-details-container .left-col .write-up .section {
        padding: 0 0 30px; }
        .group-sale-details-container .left-col .write-up .section .tips-subtitle {
          font-weight: 600;
          font-size: 16px;
          margin-top: 20px; }
      .group-sale-details-container .left-col .write-up .img-parent {
        border-radius: 8px;
        height: 150px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: inline-block;
        cursor: pointer; }
      .group-sale-details-container .left-col .write-up .img {
        border-radius: 8px;
        color: #a5a8ab;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
        height: 100%;
        cursor: pointer; }
      .group-sale-details-container .left-col .write-up .padding-over-lay {
        padding: 0 8px; }
      .group-sale-details-container .left-col .write-up .view-all-img-overlay {
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0px;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
        cursor: pointer; }
        .group-sale-details-container .left-col .write-up .view-all-img-overlay .text {
          color: white;
          font-size: 15px;
          position: absolute;
          top: 39%;
          left: 18%;
          font-weight: 600;
          text-align: center; }
    .group-sale-details-container .left-col .ant-btn-primary {
      color: #fff !important;
      background-color: #2bb8aa !important;
      border-color: #2bb8aa !important;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }
    .group-sale-details-container .left-col .ant-btn-primary:hover,
    .group-sale-details-container .left-col .ant-btn-primary:focus {
      color: #fff;
      background-color: #00c3b0 !important;
      border-color: #00c3b0 !important; }
  .group-sale-details-container .right-col {
    margin-left: 0;
    padding-left: 20px;
    padding-top: 10px; }
    .group-sale-details-container .right-col .deal-highlights {
      margin-top: 0;
      margin-bottom: 20px;
      padding-top: 0;
      padding-bottom: 12px;
      border-bottom: 1px solid #d5d8db; }
      .group-sale-details-container .right-col .deal-highlights .icon-container {
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 26px;
        color: #727578; }
      .group-sale-details-container .right-col .deal-highlights .text {
        line-height: 1.2;
        border: 0;
        color: #727578;
        padding: 0;
        text-align: center;
        font-size: 12px; }
    .group-sale-details-container .right-col .buy {
      margin: 20px 0 12px 0; }
  .group-sale-details-container .expired-text {
    color: "#fc0048"; }
  .group-sale-details-container .sticky-on-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1; }
  .group-sale-details-container .ant-rate-star {
    margin-right: 2px; }

.paypal-page {
  margin: 10px auto;
  width: 750px; }
  .paypal-page .steps-bar {
    margin-top: 50px; }
  .paypal-page .totals-item {
    text-align: right;
    display: flex;
    font-size: 14px; }
    .paypal-page .totals-item.large {
      font-size: 20px;
      font-weight: 700; }
    .paypal-page .totals-item .totals-name {
      flex: 1 1; }
      .paypal-page .totals-item .totals-name.light {
        color: rgba(0, 0, 0, 0.45); }
    .paypal-page .totals-item .totals-price {
      width: 140px; }
      .paypal-page .totals-item .totals-price .large {
        font-weight: 700; }
  .paypal-page .order-items-wrapper .order-item-header {
    display: flex; }
    .paypal-page .order-items-wrapper .order-item-header .item-price {
      text-align: right; }
  .paypal-page .paypal-btn-wrapper {
    margin-top: 10px; }

@charset "UTF-8";
.pay-now-page-container .row {
  display: flex;
  overflow: hidden;
  width: 100%; }

.pay-now-page-container .row .left-col {
  display: table-cell;
  padding: 0px 30px 0px 24px; }
  .pay-now-page-container .row .left-col .section-title {
    font-weight: 700;
    font-size: 22px;
    display: flex;
    margin-bottom: 10px; }
  .pay-now-page-container .row .left-col .steps-bar {
    margin-top: 10px;
    margin-bottom: 25px; }
    .pay-now-page-container .row .left-col .steps-bar .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: #1890ff; }
    .pay-now-page-container .row .left-col .steps-bar .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
      color: #1890ff; }
    .pay-now-page-container .row .left-col .steps-bar .ant-steps-item-title:after {
      content: "";
      height: 1px;
      width: 150px;
      background: #e8e8e8;
      display: block;
      position: absolute;
      top: 16px;
      left: 100%; }
  .pay-now-page-container .row .left-col .logo-wrapper {
    width: 100%;
    position: relative;
    height: 64px; }
    .pay-now-page-container .row .left-col .logo-wrapper:hover {
      cursor: pointer; }
    .pay-now-page-container .row .left-col .logo-wrapper:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50%;
      width: 1px;
      background: #dad0d0a6; }
    .pay-now-page-container .row .left-col .logo-wrapper .logo {
      height: 70%;
      object-fit: contain; }
    .pay-now-page-container .row .left-col .logo-wrapper .logo-text {
      color: #1c89e0;
      font-weight: 600;
      font-size: 25px; }

.pay-now-page-container .row .right-col {
  background-color: #fafafa;
  border: 2px solid #f2f2f2;
  box-shadow: -2px -1px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 28px 29px;
  width: 45%; }
  .pay-now-page-container .row .right-col – .ant-divider-horizontal {
    display: block;
    height: 1px;
    width: 100%;
    min-width: 100%;
    margin: 22px 0 !important;
    clear: both; }

.pay-now-page-container .checkout-disclaimer {
  font-size: 0.8em;
  padding: 5px 0 10px 0; }
  .pay-now-page-container .checkout-disclaimer span {
    font-weight: 600;
    color: #0089dc; }
    .pay-now-page-container .checkout-disclaimer span:hover {
      cursor: pointer; }

.pay-now-page-container .transaction {
  text-align: right;
  font-size: 0.8em;
  color: #333333;
  padding-top: 5px;
  margin-bottom: -15px; }

.pay-now-page-container .change_button {
  box-shadow: none;
  border-color: transparent;
  color: #40a9ff; }
  .pay-now-page-container .change_button:hover {
    color: #33658f;
    background-color: #fff;
    border-color: transparent; }
  .pay-now-page-container .change_button:focus {
    color: #40a9ff;
    background-color: #fff;
    border-color: transparent; }

.pay-now-page-container .secure-inner-wrapper {
  font-size: 12px;
  color: #6d6d6d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 7.5px;
  margin-top: 5px; }


.amount-display-section-container {
  width: 100%;
  border: 1px solid #c3c3c3;
  border-radius: 7px;
  height: 65px;
  background: #fff;
  font-size: 22px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .amount-display-section-container .add-comment-section-title {
    padding-left: 10px;
    color: #9e9e9e; }
  .amount-display-section-container .amount-display-amount-text {
    padding-right: 10px; }
    .amount-display-section-container .amount-display-amount-text .amount-display-currency-text {
      font-size: 17px;
      margin-right: 6px; }

.power-by-section-container {
  overflow: hidden;
  text-align: center;
  color: #c7c7c7;
  padding-top: 20px;
  font-size: 22px; }
  .power-by-section-container:before, .power-by-section-container:after {
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 10%; }
  .power-by-section-container:after {
    left: 0.5em;
    margin-right: -50%; }
  .power-by-section-container:before {
    right: 0.5em;
    margin-left: -50%; }

.add-comment-section-container {
  font-size: 22px; }
  .add-comment-section-container .pointer {
    cursor: pointer; }
  .add-comment-section-container .add-comment-section-buttons-container {
    color: #7eb3dc;
    margin-bottom: 1.5vw;
    display: flex;
    justify-content: space-between; }
    .add-comment-section-container .add-comment-section-buttons-container .contact-merchant-btn {
      color: #7eb3dc; }
  .add-comment-section-container .add-comment-comment-container {
    width: 100%;
    border: 1px solid #c3c3c3;
    border-radius: 7px;
    min-height: 65px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */ }
  .add-comment-section-container .add-comment-comment-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0; }

.self-checkout-customer-comment-input-container {
  position: relative; }
  .self-checkout-customer-comment-input-container .self-checkout-customer-comment-input-input {
    width: 100%; }
  .self-checkout-customer-comment-input-container .self-checkout-customer-comment-input-counting-indicator {
    position: absolute;
    color: #aeaeae;
    right: 10.5px;
    bottom: 4.5px; }
  .self-checkout-customer-comment-input-container .self-checkout-customer-comment-delete-icon {
    position: absolute;
    color: #aeaeae;
    right: 58.5px;
    bottom: 6.0px;
    font-size: 23px; }

.number-pad-container {
  width: 100%;
  font-size: 35px;
  margin-top: 30px; }
  .number-pad-container.show {
    -webkit-animation: num-pad-show 0.25s;
            animation: num-pad-show 0.25s; }
  .number-pad-container.hide {
    -webkit-animation: num-pad-hide 0.2s;
            animation: num-pad-hide 0.2s;
    transform: translateY(45vh); }
  .number-pad-container .number-pad-grid {
    display: grid;
    grid-template: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr;
    grid-gap: 0.3vw;
    background-color: white;
    padding: 0.3vw;
    width: 100%; }
    .number-pad-container .number-pad-grid .grid-item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e6f0fa;
      color: #4688c8;
      padding: 8px 0; }
      .number-pad-container .number-pad-grid .grid-item:active {
        background-color: #b4d6ec; }
      .number-pad-container .number-pad-grid .grid-item.disable {
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%); }
    .number-pad-container .number-pad-grid .pay-grid {
      grid-column-start: 4;
      grid-row: 2/5;
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%); }
      .number-pad-container .number-pad-grid .pay-grid.allow-order {
        -webkit-filter: grayscale(0%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(0%); }
    .number-pad-container .number-pad-grid .zero-numpad-grid {
      grid-column: span 2; }
    .number-pad-container .number-pad-grid .del-numpad-grid,
    .number-pad-container .number-pad-grid .hide-numpad-grid {
      font-size: medium;
      font-size: initial; }

@-webkit-keyframes num-pad-show {
  0% {
    transform: translateY(40vh); }
  100% {
    transform: translateY(0); } }

@keyframes num-pad-show {
  0% {
    transform: translateY(40vh); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes num-pad-hide {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(40vh); } }

@keyframes num-pad-hide {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(40vh); } }

body {
  color: #333333; }
  body h1,
  body h2,
  body h3,
  body h4,
  body h5,
  body h6 {
    color: #333333;
    margin-bottom: 0.4em; }

.link {
  color: black;
  cursor: pointer;
  outline: none;
  background-color: transparent; }
  .link:hover {
    color: black; }

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
  color: #1890ff; }

.ant-btn-primary {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
  color: #1890ff; }

.ant-pagination-item-active {
  border-color: #2bb8aa;
  font-weight: 500; }

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #2bb8aa; }

.top-menu-Selection {
  color: #ffffff !important; }

.ant-slider-track {
  background-color: #00c3b0 !important; }

.ant-slider-handle {
  border: solid 2px #fc7d2f;
  background-color: #fff; }

.ant-slider-handle:focus {
  border-color: #fc7d2f;
  box-shadow: 0 0 0 5px rgba(247, 127, 14, 0.2); }

.ant-slider-handle.ant-tooltip-open {
  border-color: #fc5f00; }

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #fc7d2f; }

.ant-input:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input:focus {
  border-color: #d9d9d9;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.2);
  border-right-width: 1px !important; }

.ant-radio-inner:after {
  background-color: #fc7d2f; }

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #fc7d2f; }

.ant-radio-checked .ant-radio-inner {
  border-color: #fc7d2f; }

.ant-radio-checked:after {
  border: 1px solid #fc7d2f; }

