$priceWidth: 140px;
$sectionPadding: 5px 15px;
$boldFontWeight: 700;


.paypal-page {

    margin: 10px auto;
    width: 750px;

    .steps-bar {
        margin-top: 50px;
    }

    .totals-item {
        text-align: right;
        display: flex;
        font-size: 14px;

        &.large {
            font-size: 20px;
            font-weight: $boldFontWeight;
        }

        .totals-name {
            flex: 1;

            &.light {
                color: rgba(0, 0, 0, 0.45);
            }
        }

        .totals-price {
            width: $priceWidth;

            .large {
                font-weight: $boldFontWeight;
            }
        }
    }


    .order-items-wrapper {
        .order-item-header {
            display: flex;

            .item-price {
                text-align: right;
            }
        }
    }

    .paypal-btn-wrapper {
        margin-top: 10px;
    }
}