.shippingMethodModalContainer {
    .radioItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
       
        .radioItemWrapper {
            width: 450px;
            margin: 5px 0;
        }
    }

    .descripTitle {
        margin: 5px 0;
        font-size: 15px;
        font-weight: 600;
    }
}