.group-sale-search-container {
    margin: 0 auto;
    max-width: 1080px;
    color: #727578;
    font-size: 1.4rem;
    font-weight: 400;

    .pull-page-header {
        position: relative;
        border: none;
        margin: 16px 0;

        .brower-header {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: 35px;
                display: inline-block;
                margin-top: 0;
                margin-bottom: 0;
                font-weight: 300;
            }

            .sort {
                display: flex;
                justify-content: space-between;
                align-self: flex-end;
                background-color: white !important;
            }

            .ant-btn {
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
                color: rgba(0, 0, 0, 0.65)
            }
            .ant-btn:hover,
            .ant-btn:focus {
                color:rgba(0, 0, 0, 0.65);
                background-color: #fff;
                border-color: #d9d9d9;
            }
        }
    }


    .dotted-line {
        padding: 0;
        margin: 10px 0 0;
        height: 1px;
        width: 100%;
        border-bottom: black 1px dotted;
    }

    .left-col {
        padding-right: 24px;

        .reset-all-container {
            padding-top: 8px;
            display: flex;
            justify-content: flex-start;
            
            .reset-pill {
                border-radius: 2px;
                cursor: pointer;
                border-radius: 2px;
                display: flex;
                font-size: 14px;
                margin: 0 8px 8px 0;
                padding: 3px 8px 4px 12px;
                background-color: #fc5f00;
                color: white;

                .content {
                    margin-right: 2px;
                    margin-left: 6px;
                }
                .icon {
                    margin: auto 0
                }
            }
        }
    }

    .right-col {
        padding-top: 10px;

        .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
        }
    }

}