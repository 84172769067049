.time-picker-modal {
    margin: -24px;
    display: flex;

    .dateScrollMenu {
       width: 45%;
       border-right: 1px solid #e8e8e8;
       overflow: auto;
        .button-item {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 16px 12px;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 1px solid #e8e8e8;

            .month {
                margin-left: 10px;
                // margin-top: 10px;
                font-weight: 700;
            }

            .dateScrollMenu-date-button {
                width: 100%;
                border-radius: 0;
                .top-text {
                    font-size: 10px;
                }

                .bottom-text {
                    font-weight: 900;
                    font-size: 19px;
                }

                .single-line-text {
                    font-weight: 900;
                    font-size: 12px;
                }

            }
        }

        .selected {
            background-color: #F2F2F2;
        }

        .no-border {
            border: none;
        }

        .invalidDate {
            border: 1px solid #fc0048;
        }

        .inValidDateMessage {
            color: #fc0048;
            margin-bottom: 10px;
            font-size: 15px;
            display: flex;
            justify-content: flex-start;
            align-content: center;
        }


    }

    .radio-row {
        padding: 16px 0;
        cursor: pointer;

        .opt-descript {
            font-weight: 500;
            font-size: 15px;
            padding-left: 20px;
        }

        .radio-button {
            display: flex;
            justify-content: center;
            align-items: center
        }
    }

    .selected-time {
        background-color: #F2F2F2
    }

    .radio-divider {
        margin: 0;

    }

    .date-time-divider {
        margin: 0
    }
}