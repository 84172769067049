.map-search-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .map-container {
    flex: 1;
  }

  .search-bar {
    width: 100%;
  }
}

.currentLocationButton {
  cursor: pointer;
  font-weight: bolder;
  color: black;
  position: fixed;
  border-radius: 100px;
  background-color: white;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  max-width: 180px;
  top: 135px;
  right: 184px;
  z-index: 999;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);

  div {
    margin: 0 6px;
  }

  .currentLocationIcon {
    display: flex;
    align-items: center;
  }

  .currentLocationText {
    margin-bottom: 0px;
  }
}

.map-search-bar-place-holder {
  display: flex;
  justify-content: space-between;

  .map-search-bar-place-holder-google-text {
    font-size: 12px;
  }
}