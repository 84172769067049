.power-by-section-container {
    overflow: hidden;
    text-align: center;
    color: #c7c7c7;
    padding-top: 20px;
    font-size: 22px;

    &:before,
    &:after {
        // background-color: #c7c7c7;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 10%;
    }

    &:after {
        left: 0.5em;
        margin-right: -50%;
    }

    &:before {
        right: 0.5em;
        margin-left: -50%;
    }
}