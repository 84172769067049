.collapse-panel-container {
    background-color: #FAFAFA;

    .icon {
        display: flex;
        justify-content: center;
    }

    .description {
        text-align: center;
        margin: 15px auto;
        display: flex;
        justify-content: center;
    }
}

.ant-collapse-content-box {
    background-color: #FAFAFA;
}

.header-text {
    // padding-left: 15px;
    font-size: 16px;
}

.header-img {
    margin: -5px 20px 0 15px;
    width: 35px;
}