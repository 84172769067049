.category-tree-list-container {
    color: #333;
    font-size: 14px;

    .cat-title {
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 0;
        padding-bottom: 5px;
        margin-top: 10px;
        color: #333;
        font-size: 23px;
        font-weight: 500
    }

    .cat-item {
        margin: 12px 0;
        cursor: pointer;
    }

    .highlighted {
        font-weight: 600;
    }

    .margin-left {
        margin-left: 15px;
    }

    .left-padding {
        padding-left: 30px;
    }

    .list {
        font-weight: 400;


    }
}