.shippingmethod-page-container {
    margin: 25px 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 19px;
    font-weight: 500;

    .shipping-method-option-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;


        .option-time-picker-option-divider {
            font-weight: 300;
            margin: 0 10px;
            color: black
        }

        .option {
            margin-left: 7px;
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }

        .option-avaliable-reminder {
            color: #323358;

            &:hover {
                color: #323358;
            }
        }

        .option-avalable-reminder-icon {
            margin-left: 2px;
            color: #323358;

            &:hover {
                color: #323358;
            }
        }

    }

    .delivery_option_error {
        width: 60px;
        padding-top: 20px;
        border-bottom: 1px solid #fc0048;
    }

}

.ant-modal.shipping-main-modal {
    top: 30%;
}