.content-container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  .logo-wrapper {
    text-align: center;
    padding: 30px 0;
    .logo {
      width: 230px
    }
  }
  .auth-form-container {
    // width: 700px;
    // margin: auto;
    background-color:#ffffff;
    .box {
      width: 100%;
      margin: auto;
      background: white;
      padding: 20px 0px 20px 0px;

      .link {
        margin-top:-25px;
        margin-bottom:15px;
      }

      .ant-input-group .ant-input {
        float: left;
        margin-bottom: 0;
        .ant-input-lg {
          margin-bottom:0;
        }
      }

      .ant-modal-body {
        padding: 0 -5px 0 -5px;
      }
      
      .mediaCol {
        text-align: center;
      }
      
      .login-icon {
        margin-right:4px;
      }
      .vc-input-wrapper {
        display: flex;
        .vc-input-field {
          margin-right: 10px;
        }
      }
    
      .ant-layout-content{
        height:400px;
      }
    
      .alt-login-intro {
        text-align:center;
        margin-top:-10px;
        margin-bottom:16px;
        margin-left:auto;
        margin-right:auto;
    
      }
    
      .input-spacing-top {
        margin-top:-8px;
      }
    
      .vc-input-wrapper {
        margin-top:-8px;
        .phone-input{
          width:160px;
        }
        .ver-button{
          width:150px;
          padding:0 5px 0;
          margin-left:10px;
          text-align: center;
          flex:1;
    
        }
      }
      .login-form-button {
        margin:-6px 0 -7px 0;
      }
      .ant-form-item-control {
        line-height: 0px;
      }
      .missing-item-message {
        margin-top:10px;
      }
    }
  }
}


