.group-sale-cell-container {

  border-bottom: 1px #E6E7E7 solid;
  cursor: pointer;
  
  //ideas from https://css-tricks.com/zooming-background-images/ 
  .img-parent {
    border-radius: 8px;
    height: 230px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    overflow: hidden;
  }

  .img:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52, 73, 94, 0.75);
  }

  &:hover {
    border-bottom: 1px #727578 solid;
  }

  &:hover .img,
  &:focus .img {
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  &:hover .img:before,
  &:focus .img:before {
    display: block;
  }

  .name {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
  }

  .descript {
    color: #808080;
    white-space: nowrap;
    overflow: hidden;
  }

  .localsPrice {
    color: #808080;
    font-size: 13px;
    display: flex;
    justify-content: space-between;

    .local {
      align-self: flex-end;
    }

    .oldPrice {
      color: black;
      text-decoration: line-through;
      font-size: 19px;
      font-weight: 400;
      margin: 0 3px
    }

    .newPrice {
      margin-left: 2px;
      font-size: 19px;
      font-weight: 400;
      color: #2bb8aa;
    }
  }

  .rateDiscount {
    color: #808080;
    font-size: 13px;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    line-height: 19px;
    margin-bottom: 12px;

    .rating-number {
      margin-right: 2px;
      align-self: flex-end;
      font-size: 13px;
    }

    .rating-count {
      margin-left: -6px;
      font-size: 13px;
    }
  }

  .price-discount {
    display: inline;
    color: #2bb8aa  ;
    text-align: center;
    vertical-align: middle;
    padding: 1px 12px;
    background-color: #F2F2F4;
  }

  .ant-rate-star {
    margin-right: 3px;
  }
}
