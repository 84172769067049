.content-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .login-form {
        width: 400px;
    }

    .logo-wrapper {
        text-align: center;
        padding: 30px 0;

        .logo {
            width: 230px
        }
    }

    .auth-form-container {

        // width: 700px;
        // margin: auto;
        .box {
            width: 350px;
            margin: auto;
            background: white;
            padding: 23px 26px;
        }
    }

    .vc-input-wrapper {
        display: flex;

        .vc-input-field {
            margin-right: 10px;
        }

        .login-page-phone-input {
            margin-right: 4px;
        }

        .login-page-get-vc-button {
            margin-left: 4px;
        }
    }
}