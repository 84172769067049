.myaccount-sidebar-container {
  .ant-menu-item {
    color: #707070;
    &:hover {
      color: #1890ff;
    }
  }
  .ant-menu-item {
    color: #707070;
    &:hover {
      color: #1890ff;
    }
  }
  .ant-menu-submenu .ant-menu-submenu-title {
    color: #707070;
    &:hover {
      color: #1890ff;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(24, 144, 255, 0.178);
    color: #707070;
  }
  .ant-menu-vertical .ant-menu-item:after,
  .ant-menu-vertical-left .ant-menu-item:after,
  .ant-menu-vertical-right .ant-menu-item:after,
  .ant-menu-inline .ant-menu-item:after {
    border-right: 3px solid #1890ff;
  }
  .ant-menu-submenu-arrow:hover {
    color: #1890ff !important;
  }
}
