.group-sale-details-container {
    margin: 0 auto;
    max-width: 1080px;
    padding-top: 20px;
    font-size: 1.1rem;
    position: relative;

    .header-section {
        margin-bottom: 15px;

        .title {
            font-size: 2.2rem;
            font-weight: 500;
            margin: 0 8px 0 0;
            line-height: 1.5;
            color: #333;
            align-items: center;
            display: flex;
            margin-bottom: 0.4rem;
            position: relative;
            letter-spacing: normal;
            line-height: normal;
        }

        .location {
            color: #808080;
            font-size: 13px;
            line-height: 19px;
            align-items: center;
            display: flex;
            margin: 10px 0 10px 0;
            justify-content: flex-start;

            .icon {
                display: flex;
                font-size: 1.05rem;
                line-height: 1;
                margin-right: 0.4rem;
            }
        }
    }

    .rate {
        color: #727578;
        font-weight: 400;
        display: table;
        padding-right: 5px;
        font-size: 0.9rem;
        line-height: 1.5;
        font-size: 19px;
        line-height: 18px;

        .rating-stars {
            margin: 0 10px 2px 0;
            font-weight: 400;
        }

        .rating-numbers {
            color: #0081e3;
            font-weight: 400;
            font-size: 0.9rem;
        }

        .rating-count {
            color: #0081e3;
            font-size: 0.9rem;
        }
    }

    .left-col {
        border-right: 1px solid #e6e7e8;
        margin-right: 0;
        padding-right: 20px;

        .write-up {
            overflow: visible;
            position: relative;
            padding-top: 30px;
            margin-bottom: 20px;

            .write-up-title {
                font-size: 1.8rem;
                font-weight: 700;
                color: #333;
                margin: 10px 0;
            }

            .p {
                word-wrap: break-word;
                margin: 0 0 20px;
            }

            .section {
                padding: 0 0 30px;

                .tips-subtitle {
                    font-weight: 600;
                    font-size: 16px;
                    margin-top: 20px;
                }
            }

            .img-parent {
                border-radius: 8px;
                height: 150px;
                width: 100%;
                overflow: hidden;
                position: relative;
                display: inline-block;
                cursor: pointer;
            }

            .img {
                border-radius: 8px;
                color: #a5a8ab;
                object-fit: cover;
                overflow: hidden;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }

            .padding-over-lay {
                padding: 0 8px;
            }

            .view-all-img-overlay {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0px;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 1;
                cursor: pointer;

                .text {
                    color: white;
                    font-size: 15px;
                    position: absolute;
                    top: 39%;
                    left: 18%;
                    font-weight: 600;
                    text-align: center;
                }
            }
        }

        .ant-btn-primary {
            color: #fff !important;
            background-color: #2bb8aa !important;
            border-color: #2bb8aa !important;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        }

        .ant-btn-primary:hover,
        .ant-btn-primary:focus {
            color: #fff;
            background-color: #00c3b0 !important;
            border-color: #00c3b0 !important;
        }
    }

    .right-col {
        margin-left: 0;
        padding-left: 20px;
        padding-top: 10px;

        .deal-highlights {
            margin-top: 0;
            margin-bottom: 20px;
            padding-top: 0;
            padding-bottom: 12px;
            border-bottom: 1px solid #d5d8db;

            .icon-container {
                margin-bottom: 8px;
                display: flex;
                justify-content: center;
                align-content: center;
                font-size: 26px;
                color: #727578;
            }

            .text {
                line-height: 1.2;
                border: 0;
                color: #727578;
                padding: 0;
                text-align: center;
                font-size: 12px;
            }
        }

        .buy {
            margin: 20px 0 12px 0;
        }
    }

    .expired-text {
        color: "#fc0048";
    }

    .sticky-on-top {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .ant-rate-star {
        margin-right: 2px;
    }
}