@import "~antd/dist/antd.css";

body {
  color: #333333;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333333;
    margin-bottom: 0.4em;
  }
}

.link {
  color: black;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  &:hover {
    color: black;
  }
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
  color: #1890ff;
}

//Buttons
.ant-btn-primary {
  // color: #fff !important;
  // background-color: #2bb8aa !important ;
  // border-color:#2bb8aa !important ;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
  color: #1890ff;
}
//Pagination
.ant-pagination-item-active {
  border-color: #2bb8aa;
  font-weight: 500;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #2bb8aa;
}

//Top-Menu Styling
.top-menu-Selection {
  color: #ffffff !important;
}

//Slider
.ant-slider-track {
  background-color: #00c3b0 !important;
}
.ant-slider-handle {
  border: solid 2px #fc7d2f;
  background-color: #fff;
}
.ant-slider-handle:focus {
  border-color: #fc7d2f;
  box-shadow: 0 0 0 5px rgba(247, 127, 14, 0.2);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #fc5f00;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #fc7d2f;
}

//Input
.ant-input:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #d9d9d9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.2);
  box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.2);
  border-right-width: 1px !important;
}

//Radio Button
.ant-radio-inner:after {
  background-color: #fc7d2f;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #fc7d2f;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #fc7d2f;
}
.ant-radio-checked:after {
  border: 1px solid #fc7d2f;
}
