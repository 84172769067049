.top-menu-container {
    display: flex;
    max-height: 100%;
    margin: auto 0;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    margin-top: 50px;

    .top-menu-Selection{
        color: white !important;
        height: 2.85rem;
        padding: 0 2.6rem;
        margin: 0 -1.2rem;
    }
    .top-menu-Selection:hover{
        color: white !important;
        width: 300px !important;
        background-color: #00c3b0;
        padding: .65rem 2.6rem;
    }
    .top-menu-longSelection{
        color: white !important;
        height: 2.85rem;
        padding: 0 1.6rem;
        margin: 0 -1.2rem;
    }
    .top-menu-longSelection:hover{
        color: white !important;
        background-color: #00c3b0;
        padding: .65rem 1.6rem;
    }
}

