.sort_button-container {
    .sort_title {
        margin-right: 20px;
    }

    .icon {
        font-size: 13px;
    }
}

.menu {
    position: absolute;
    top: 13px;
    z-index: 10;
    background-color: #fff;
    padding: 0 0 16px 16px;
    width: 253px;
    border: 1px solid #dfe5e8;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.37);
    border-radius: 2px;
}

.menu:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: -11px;
    right: 3%;
    transform: translateX(-50%) rotate(45deg);
    background-color: #fff;
    border-top: 1px solid #dfe5ec;
    border-left: 1px solid #dfe5ec;
    width: 19px;
    height: 19px;
    box-shadow: -2px -2px 2px rgba(0, 0, 0, .1)
}