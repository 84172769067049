.payment-info-container {
    .title {
        font-size: large;
        font-weight: 600;
    }

    .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .checkout-billinginfo {
        font-weight: bold;
    }

    .action {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .list-information {
        padding: 0px 10px 10px 25px;
    }
}