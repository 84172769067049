.bookmarks-content-container {
  display: flex;
  .bookmark-section-container {
    flex: 1;
    padding: 0 15px;
    &:last-child {
      border-left: 1px solid #eee;
    }
    .bookmark-list-wrapper {
      .avatar-wrapper {
        width: 60px;
        height: 60px;
        img {
          object-fit: contain;
        }
      }
      .rating-wrapper {
        font-size: 15px;
        .ant-rate-star {
          margin-right: 4px;
        }
      }
    }
  }
}