

.review-modal-content {
   padding-right: 10px;
}

.ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}