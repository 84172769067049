.add-comment-section-container {
    font-size: 22px;

    .pointer {
        cursor: pointer;
    }

    .add-comment-section-buttons-container {
        color: #7eb3dc;
        margin-bottom: 1.5vw;
        display: flex;
        justify-content: space-between;

        .contact-merchant-btn {
            color: rgb(126, 179, 220); // not sure why this anchor tag doesn't inheirit .action-containers text-color. Might be it being an anchor tag
        }
    }

    .add-comment-comment-container {
        width: 100%;
        border: 1px solid #c3c3c3;
        border-radius: 7px;
        min-height: 65px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        padding: 10px;
        overflow-y: scroll;
        scrollbar-width: none; //hide scroll bar
        /* Firefox */
        -ms-overflow-style: none; //hide scroll bar
        /* Internet Explorer 10+ */
    }

    //hide scroll bar
    .add-comment-comment-container::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }
}

.self-checkout-customer-comment-input-container {
    position: relative;

    .self-checkout-customer-comment-input-input {
        width: 100%;
    }

    .self-checkout-customer-comment-input-counting-indicator {
        position: absolute;
        color: #aeaeae;
        right: 10.5px;
        bottom: 4.5px;
    }

    .self-checkout-customer-comment-delete-icon {
        position: absolute;
        color: #aeaeae;
        right: 58.5px;
        bottom: 6.0px;
        font-size: 23px;
    }
}