.map-drawer-conatiner {
  .ant-drawer-wrapper-body {
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    .ant-drawer-body {
      
      flex: 1;
      display: flex;
      flex-direction: column;
      .map-search-confirm-btn {
        margin: 20px 0;
      }
    }
  }
}