.group-sale-landing-container {
    margin: 0 60px;

    .welcomeMessage {
        font-size: 25px;
        margin-bottom: 22px;
        margin-top: 20px;
    }

    .top-three-container {
        margin-bottom: 30px;
    }

    .listTitle {
        border-bottom: none;
        margin: 0;
        padding: 0;
        width: 100%;
        line-height: 25px;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        color: #343434;
        letter-spacing: .4px;
    }

    .pop-list-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }

    .descript {
        margin-top:3px;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
    }

    .localsPrice{
        margin-top: 3px;
    }
}