.account-content-wrapper {
  margin: auto;
  // border: 1px solid #eee;
  display: flex;
  .user-section {
    padding: 5px 15px;
    &:last-child {
      border-left: 1px solid #eee;
    }
  }
  .user-left-section {
    display: flex;
    flex: 1;
    .user-info-header {
      display: flex;

    }
    .user-info-body {
      flex: 1;
      padding-left: 15px;
      .name {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .user-info-footer {
      display: flex;
      .edit-icon {
        cursor: pointer;
        font-size: 20px;
        color: '#333333';
      }
    }
  }
  .user-right-section {
    flex: 0.6;
    flex-direction: column;
    align-items: center;
    .order-item {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 5px;
      border-radius: 5px;
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      &:hover {
        background-color: #e6f7ff;
      }
      // justify-content: space-around;
      .order-item-header {
        flex: 0.7;
        // text-align: center;
      }
      .order-item-body {
        flex: 2
      }
      .order-item-footer {
        flex: 1;
        text-align: right;
      }
    }
    .no-orders-wrapper {
      text-align: center;
      .text {
        padding-bottom: 7px;
      }
    }
    .view-all-btn {
      text-align: right;
      font-size: 12px;
    }
    .action-btn {
      width: 80%;
      margin-bottom: 12px;
    }
  }
}

.vc-input-wrapper {
  display: flex;
  .vc-input-field {
    margin-right: 10px;
  }
}