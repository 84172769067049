.points-modal-container {
  .current-value {
    width: 70%;
    font-size: 1.5em;
    font-weight: 600;
    display: inline-block;
  }
  .points-button {
    position: absolute;
    right: 30px;
    display: inline-block;
  }
}
