.store-info-container {
    .merchant-header {
        display: flex;
        margin-bottom: 16px;
        min-height: 56px;

        .icon-merchant {
            border: 1px solid #e6e7e8;
            font-size: 28px;
            line-height: 1;
            padding: 14px;
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .header-text {
            margin-left: 17px;

            .store-name {
                line-height: 1.2;
                margin: 10px 0 5px 0;
                font-weight: 500;
                font-size: 20px;
                color: #333;
            }

            .store-services {
                color: #727578;
                font-size: 14px;
            }
        }
    }

    .store-map-wrapper {
        height: 200px;
        width: 100%;
    }

    .address-bar {
        align-items: center;
        border: solid;
        border-width: 0 1px 1px 1px;
        display: -ms-flexbox;
        display: box;
        display: flex;
        -o-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 75px;
        padding: 10px 20px;

        .marker {
            margin-right: 20px;
            min-width: 26px;
        }

        .address-area {
            flex-grow: 1;

            .address-top {
                display: flex;
                font-weight: bolder;
            }

            .address-bottom {
                line-height: 1.5;
                font-size: 13px;
                font-weight: 300;
            }
        }

    }

    .merchant-attribute {
        border: solid;
        border-width: 0 1px 1px;
        display: -ms-flexbox;
        display: box;
        display: flex;
        -o-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 10px;

        .contact-info {
            margin-bottom: 0;
            padding: 0 7px;
            width: 33%;
            font-size: 14px;

            .contact-row {
                align-items: center;
                display: -ms-flexbox;
                display: box;
                display: flex;
                padding: 8px 0;
            }
        }

        .merchant-hours {
            width: 67%;

            .merchant-status {
                display: flex;
                padding: 8px 0 4px 0;

                .mechant-icon {
                    height: 19px;
                    margin: 1px 12px 0 0;
                }
                .mechant-status {
                    font-weight: bolder;
                    color: #2bb8aa;
                    font-size: 18px;
                }

            }

            .open-hour {
                margin-left: 42px;
                font-size: 14px;
                display: flex;
                .day {
                    width: 15%
                }
                .hour {
                    width: 50%
                }
            }

            .toggle {
                color: #2bb8aa;
                cursor: pointer;
                font-size: 15px;
                margin-left: 42px;
            }
        }

    }


    .c-bdr-gray-lt {
        border-color: #d5d8db !important;
    }

}