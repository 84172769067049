.large-group-sale-cell-container {

    border-bottom: 1px #E6E7E7 solid;
    margin-bottom: 16px;
    cursor: pointer;

    //ideas from https://css-tricks.com/zooming-background-images/ 
    .img-parent {
        height: 288px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: inline-block;
    }

    .img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;
        overflow: hidden;
    }

    .img:before {
        content: "";
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(52, 73, 94, 0.75);
    }

    &:hover {
        border-bottom: 1px #727578 solid;
    }

    &:hover .img,
    &:focus .img {
        -ms-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
    }

    &:hover .img:before,
    &:focus .img:before {
        display: block;
    }

    .name {
        margin-top: 8px;
        margin-bottom: 8px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        max-height: 40px;
        overflow: hidden;
    }

    .descript {
        overflow-wrap: break-word;
        word-wrap: break-word;
        font-size: 13px;
        padding: 0;
        height: 80px;
        color: #727578!important;
        font-weight: 400;
        margin: 0 0 20px 0;
        overflow: hidden;
    }

    .locals {
        color: #808080;
        font-size: 13px;
        display: flex;
        justify-content: flex-start;
    }

    .rateDiscount {
        color: #808080;
        font-size: 13px;
        margin: 2px 0;
        display: flex;
        justify-content: flex-start;
        line-height: 19px;
        margin-bottom: 12px;

        .rating-number {
            margin-right: 2px;
            align-self: flex-end;
            font-size: 13px;
        }

        .rating-count {
            margin-left: -6px;
            font-size: 13px;
        }
    }

    .localsPrice {
        color: #808080;
        font-size: 13px;
        display: flex;
        justify-content: flex-end;

        .oldPrice {
            align-self: flex-end;
            color: #727578!important;
            font-size: 13px;
            font-weight: 400;
            text-decoration: line-through;
            font-weight: 400;
            margin: 0 3px
        }

        .newPrice {
            margin-left: 3px;
            font-size: 24px;
            font-weight: 600;
            color: #2bb8aa;
        }
    }

    .price-discount-wrapper {
        display: flex;
        justify-content: flex-end;
        .price-discount {
            text-align: right;     
            border-radius: 3px;
            color: #2bb8aa;
            font-size: 12px;
            height: 20px;
            padding: 2px 8px;
            vertical-align: middle;
            display: inline;
            background-color: #F2F2F4;
        }
    }

    .button-wrapper {
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
    }

    .ant-rate-star {
        margin-right: 3px;
    }
}