.ads-container {
    position: relative;
    display: inline-block;
    justify-content: center;
    //background-color:hotpink;
    width: 970px;
    height: 90px;
    margin: 22px auto auto auto;
    .top-ad-bar {
       
    }
    
    .close-button {
        position: relative;
        z-index: 2;
       bottom: 430px;
       left: 955px;
        //color:black;
        color:black;
        cursor: pointer;
        
    }
}

