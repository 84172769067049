.number-pad-container {
    width: 100%;
    font-size: 35px;
    margin-top: 30px;

    &.show {
        animation: num-pad-show 0.25s;
    }

    &.hide {
        animation: num-pad-hide 0.2s;
        transform: translateY(45vh);
    }

    .number-pad-grid {
        display: grid;
        grid-template: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr;
        grid-gap: 0.3vw;
        background-color: white;
        padding: 0.3vw;
        width: 100%;

        .grid-item {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(230, 240, 250);
            color: rgb(70, 136, 200);
            padding: 8px 0;

            &:active {
                background-color: rgb(180, 214, 236);
            }

            &.disable {
                filter: grayscale(100%);
            }
        }

        .pay-grid {
            grid-column-start: 4;
            grid-row: 2/5;
            -webkit-filter: grayscale(100%);
            /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);

            &.allow-order {
                -webkit-filter: grayscale(0%);
                /* Safari 6.0 - 9.0 */
                filter: grayscale(0%);
            }
        }

        .zero-numpad-grid {
            grid-column: span 2
        }

        .del-numpad-grid,
        .hide-numpad-grid {
            font-size: initial;
        }
    }
}

@keyframes num-pad-show {
    0% {
        transform: translateY(40vh)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes num-pad-hide {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(40vh)
    }
}