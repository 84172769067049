.amount-display-section-container {
    width: 100%;
    border: 1px solid #c3c3c3;
    border-radius: 7px;
    height: 65px;
    background: #fff;
    font-size: 22px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-comment-section-title {
        padding-left: 10px;
        color: #9e9e9e;
    }

    .amount-display-amount-text {
        padding-right: 10px;

        .amount-display-currency-text {
            font-size: 17px;
            margin-right: 6px;
        }
    }
}