.address-item-wrapper {
  border: 1px solid #afafaf;
  padding: 20px;
  width: calc(33% - 18px);
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 10px;
  height: 250px;
  box-sizing: border-box;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;
  &.add-address-btn-wrapper {
    border: 1px dashed #afafaf;
    .add-address-btn-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
  }
  .address-item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
