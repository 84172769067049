.picture-video-carousel-container {
  .ant-carousel .slick-slide {
    border-radius: 10px;
    text-align: center;
    height: 412.2px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }

  .img {
    height: 412.2px;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .imgs-button-wrapper {
    left: 0;
    margin: 10px 0 0;
    min-width: 57px;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .img-small {
      border-radius: 6px;
      border: 0 solid;
      cursor: pointer;
      display: inline-block;
      height: 57px;
      margin: 0 10px 0 0;
      outline: 0 solid #fff;
      overflow: hidden;
      position: relative;
      transition: border-width 0.25s cubic-bezier(0.4, -1.5, 0.6, 2.75),
        outline-width 0.25s cubic-bezier(0.25, 2.5, 0.625, 0.25);
      width: 57px;
    }
  }

  .selected-border {
    padding: 2px;
    border: 2px solid #0081e3 !important;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 57px;
    width: 57px;
    font-size: 44px;
    border: 1px solid #cccccc;
  }

  .icon-selected {
    margin: -4px;
  }

  &:hover>.nav-arrows {
    display: block;
  }

  .nav-arrows {
    display: none;
    position: absolute;
    width: 100%;
    top: 204px;
    padding: 0 10px;

    .button {
      z-index: 2;
      background: #333;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      height: 60px;
      line-height: 65px;
      pointer-events: all;
      text-align: center;
      top: 10.5%;
      width: 60px;

      &:hover {
        background: white;
        color: black;
      }

      .carousel-icon {
        margin: auto auto;
      }

    }

    .prev {
      left: 10px;
    }

    .next {
      right: 30px;
    }
  }
}