.content {
    padding-right: 10px;
}

.ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-parent {
    border-radius: 8px;
    height: 200px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: inline-block;
    cursor: pointer;    
}

.img {
    border-radius: 8px;
    color: #a5a8ab;
    object-fit: cover;
    overflow: hidden;
    height: auto;
    max-width: 100%;
    cursor: pointer;
}