.formItem {
    margin-bottom: 6px !important;
}

.missing-item {
    border: 1px solid #fc0048;
}

.missing-item-message {
    color: #fc0048;
    margin-bottom: 10px;
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    align-content: center;

}


