.gift-card-row {
    padding: 10px 0;
    display: flex !important;
    justify-content: center;

    .gift-card-card {
        border-radius: 16px;
        -webkit-box-shadow: 6px 9px 13px 0px rgba(140, 140, 140, 1);
        -moz-box-shadow: 6px 9px 13px 0px rgba(140, 140, 140, 1);
        box-shadow: 6px 9px 13px 0px rgba(140, 140, 140, 1);
    }

    .gift-card-info {
        cursor: pointer;
    }

    .selected-card-display-row {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-align-content: flex-end;
        align-items: flex-end;
        height: 66px;

        .selected {
            color: #1890ff
        }

        .checked-box {
            font-size: 23px;
        }
    }

    .gift-card-wrapper {
        border: 1px solid #eee;
    }

    .gift-card-col {
        padding: 0 !important;
        margin: 0 8px !important;
        width: 30.166667% !important;
    }

    .selectable {
        cursor: pointer;
    }

    .gift-card-action-btn {
        height: 50px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fafafa;
        color: rgba(0, 0, 0, 0.45);
        border: 1px solid #e8e8e8;
        border-top: none;
        cursor: pointer;

        &:hover {
            color: #1890ff;
            background: #eeeeeee0;
        }
    }
}

.gift-card-detail-content {

    // min-width: 500px;
    .gift-card-detail-top {
        display: flex;
        width: 100%;
        margin-bottom: 15px;

        .general-info-wrapper {
            flex: 0.7;
            padding: 15px;
        }

        .transfer-form-wrapper {
            flex: 1;
            border-left: 1px solid #eee;
            padding: 15px;
        }
    }

    .gift-card-detail-bottom {}


}